import {Component} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import LocalizedStrings from "react-localization";
import Footfall from "./Footfall";
import RoiSettings from "../RoiSettings/RoiSettings";
import Dolak from "../Dolak/Dolak";

let strings = new LocalizedStrings({
    en: {
        footfall: "Footfall",
        preparing: "Preparing",
        roiSettings: "ROI Settings",
        dolakAnalytics: 'Dolák'
    },
    cs: {
        footfall: "Průchody",
        preparing: "Připravujeme",
        roiSettings: "Nastavení oblastí",
        dolakAnalytics: 'Dolák'
    }
})

export default class Analytics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menu: []
        }
    }


    componentDidMount() {
        let menu = [];
        // menu.push('preparing');
        if (this.props.api.user.isAllowed('Analytics', 'footfall')) {
            menu.push('footfall');
        }
        if (this.props.api.user.isAllowed('Analytics', 'roi-settings')) {
            menu.push('roiSettings');
        }
        if (this.props.api.user.isAllowed('Analytics', 'dolak')) {
            menu.push('dolakAnalytics');
        }
        this.setState({menu: menu})
    }

    render() {
        let tabs_string = [];
        let tabs_content = [];
        let tabs_source = {
            'preparing': <div className="analytics"><div className="item-desc"><h1>Stránku pro vás připravujeme</h1><p>Už brzy si zde budete moci zobrazit veškerou analytiku uspořádanou do přehledných grafů. </p></div></div>,
            'footfall': <Footfall {...this.props} />,
            'dolakAnalytics': <Dolak {...this.props} />,
            'roiSettings': <RoiSettings {...this.props} />
        }
        for (let index in this.state.menu) {
            let tab = this.state.menu[index];
            tabs_string.push(<Tab key={tab}>{strings[tab]}</Tab>)
            if (tabs_source[tab]) {
                tabs_content.push(<TabPanel key={'tab-content'+tab}>{tabs_source[tab]}</TabPanel>);
            } else {
                tabs_content.push(<TabPanel key={'tab-content'+tab}>No content</TabPanel>);
            }
        }

        return (
            <div>
                <Tabs>
                    <TabList>
                        {tabs_string}
                    </TabList>
                    {tabs_content}
                </Tabs>
            </div>
        )
    }
}
