import {Component} from "react";
import PropTypes from 'prop-types';

export default class TorlinModal extends Component {
    constructor(props) {
        super(props);
        this.text = this.props.text ? this.props.text : 'Opravdu chcete akci provést?';
        this.confirmText = this.props.confirmText ? this.props.confirmText : 'Potvrdit';
        this.state = {
            open: this.props.open,
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.open !== this.props.open) {
            this.setState({open: this.props.open})
        }
    }


    render() {
        return this.state.open?<>
            <i className={'page-modal-overlay background' + (this.state.open ? ' active' : '')}/>
            <div className={'page-modal-content-reset-position'}>
            <div className={'page-modal-content' + (this.state.open ? ' active' : '')}>
                {this.props.html?this.props.html:<h3 className={'bottom-margin'}>{this.text}</h3>}

                <button onClick={(event) => {
                    event.preventDefault();
                    this.setState({open: false})
                    if (this.props.cancelCallback) this.props.cancelCallback();
                }} className="btn-gray">Zrušit
                </button>
                <button onClick={(event) => {
                    event.preventDefault();
                    this.setState({open: false});
                    this.props.callback()
                }} className="btn-red">{this.confirmText}
                </button>
            </div></div>
        </>:null
    }
}

TorlinModal.propTypes = {
    callback: PropTypes.func.isRequired,
    cancelCallback: PropTypes.func.isRequired
}
