import {Component} from "react";

export default class SelectFromList extends Component {
    render() {
        return (
            <select defaultValue={this.props.selected} onChange={this.props.onSelect?this.props.onSelect:null}>
                {this.props.data.map(item=>{
                    return <option key={"option_"+item.id} value={item.id}>{item.label}</option>
                })}
            </select>
        )
    }
}
