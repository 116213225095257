import {Component} from "react";
import CameraCell from "./CameraCell";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        showAll: 'Show all'
    },
    cs: {
        showAll: 'Zobrazit vše'
    }
})

export default class CamerasRow extends Component {

    constructor(props) {
        super(props);
        let state = this.getCameraRowState();
        this.state = {
            limitPerRow: 4,
            open: state,
            divStyle: state?' camera-item-act':''
        }
        this.toggleOpenState = this.toggleOpenState.bind(this);
    }


    getCameraRowState() {
        let name = 'torlin_camera_open_row_state_id_'+this.props.data.id;
        let state = localStorage[name];
        return state === "true";
    }


    saveCameraRowState(new_state) {
        let name = 'torlin_camera_open_row_state_id_'+this.props.data.id;
        localStorage[name] = new_state;
    }


    toggleOpenState() {
        let new_state = !this.state.open;
        this.saveCameraRowState(new_state);
        if (new_state === false) {
            this.setState({divStyle: ''})
            this.closingRow = setTimeout(()=>{this.setState({
                open: new_state
            }); this.closingRow = null;},500);
        } else {
            this.setState({open: new_state});
        }
    }

    render() {
        if (this.state.open === true) {
            return this.renderOpen();
        } else {
            return this.renderClose();
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.open && (this.state.divStyle === '')) {
            if (!this.closingRow) this.setState({divStyle: ' camera-item-act'})
        }
        if (!this.state.open && (this.state.divStyle !== '')) {
            this.setState({divStyle: ''})
        }
    }


    renderOpen() {
        let rest_cameras = this.props.data.cameras.length-this.state.limitPerRow;
        let rest_cameras_html = '';
        if (rest_cameras>0) {
            rest_cameras_html = <span onClick={()=>{this.setState({limitPerRow: 999})}} className="btn-more">{strings.showAll} ({rest_cameras})<i/></span>
        }

        return (
            <div className={'camera-item' + this.state.divStyle }>
                <div onClick={this.toggleOpenState} className="camera-item-head">
                    <h2>{this.props.data.label}</h2>
                    <i className="ico-arrow"/>
                </div>
                <div className="camera-item-content">
                    <div className="camera-list-4">
                        {this.props.data.cameras.map((camera, index)=>{
                            if (index>=this.state.limitPerRow) return null;
                            return <CameraCell key={'camera_'+index} data={camera} favCallback={this.props.favCallback} api={this.props.api}/>
                        })}
                    </div>
                    {rest_cameras_html}
                </div>
            </div>
        )
    }

    renderClose() {
        return (
            <div className="camera-item">
                <div onClick={this.toggleOpenState} className="camera-item-head">
                    <h2>{this.props.data.label}</h2>
                    <i className="ico-arrow"/>
                </div>
            </div>
        )
    }
}
