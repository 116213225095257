import {createSlice} from '@reduxjs/toolkit';

export const dolakSlice = createSlice({
    name: 'dolak',
    initialState: {
        season: {from: null,till:null},
        days: [],
        hours: {from: null, till: null},
        needUpdate: true,
        detailView: false,
    },
    reducers: {
        setSeason (state, season) {
            const value = season.payload;
            if (value.from && value.till) {
                state.season = value;
            } else {
                state.season = {from: null, till: null}
            }
            state.needUpdate = true
        },
        setDays(state, days) {
            const value = days.payload;
            if (Array.isArray(value)) {
                state.days = value;
            } else {
                state.days = [0,1,2,3,4];
            }
            state.needUpdate = true
        },
        setHours(state, hours) {
            const value = hours.payload;
            if (value.from && value.till) {
                state.hours = value;
            } else {
                state.hours = {from: null, till: null}
            }
            state.needUpdate = true
        },
        setNeedUpdate(state,value) {
            state.needUpdate = value.payload
        },
        setDetailView(state, payload) {
            const value = payload.payload;
            if (typeof value === 'boolean') {
                state.detailView = false
            } else {
                state.detailView = {section: value.section, item: value.item}
            }
        }
    }
})

export const { setSeason, setDays, setHours,setNeedUpdate,setDetailView } = dolakSlice.actions;

export default dolakSlice.reducer;
