import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import LocalizedStrings from "react-localization";


let strings = new LocalizedStrings({
    en: {
        open: 'Open',
        close: 'Close'
    },
    cs: {
        open: 'Rozbalit',
        close: 'Sbalit'
    }
})

export default class CamSelectList extends Component {
    state = {
        loaded: false,
        places: null,
        checkedTree: null
    }

    getCheckedTree() {
        return this.state.checkedTree;
    }

    componentDidMount() {
        this.props.api.loadCameras().then(res => {
            this.props.api.getCamerasByUser(this.props.user_id).then(allowed=>{
                let allowed_tree = this.getItemsFromTree(allowed);
                let items = this.getItemsFromTree(res, allowed_tree);
                this.setState({loaded: true, places: res.cids, checkedTree: items})
            });
        });
    }


    getItemsFromTree(res, allowed) {
        let items = {}
        for (let csid in res.cids) {
            if (!res.cids.hasOwnProperty(csid)) continue;
            let csid_object = res.cids[csid];
            if (items[csid_object.id] === undefined) items[csid_object.id] = {}
            for (let room in csid_object.rooms) {
                if (!csid_object.rooms.hasOwnProperty(room)) continue;
                let room_object = csid_object.rooms[room];
                if (items[csid_object.id][room_object.id] === undefined) items[csid_object.id][room_object.id] = {}
                for (let camera in room_object.cameras) {
                    if (!room_object.cameras.hasOwnProperty(camera)) continue;
                    let camera_object = room_object.cameras[camera];
                    let calculated_allowed = false;
                    if (this.props.user_id && allowed) {
                        try {
                            calculated_allowed = allowed[csid_object.id][room_object.id][camera_object.id];
                        } catch(e) {
                            calculated_allowed = false;
                        }
                    }
                    items[csid_object.id][room_object.id][camera_object.id] = (allowed)?calculated_allowed:true;
                }
            }
        }
        return items;
    }

    render() {
        if (this.state.loaded === false) return <Loader/>
        return <div className="form-item-list camera-checkbox-list">
            <h3>Objekty:</h3>
            {this.state.places.map(place => {
                return <CsidCheckbox key={'csid_'+place.id} place={place} isChecked={this.state.checkedTree[place.id]}/>
            })}
            })
            <div className={'clear'}/>
        </div>
    }
}


class CsidCheckbox extends Component {

    state = {
        checked: false,
        opened: false,
    }

    componentDidMount() {
        let checked = true;
        for (let rid in this.props.isChecked) {
            for (let cid in this.props.isChecked[rid]) {
                if (this.props.isChecked[rid][cid] === false) {
                    checked = false
                    break;
                }
                if (!checked) break;
            }
        }
        if (checked) this.setState({checked: checked})
    }

    render() {
        let id = this.props.place.id;
        let label = this.props.place.label;
        let cams = this.state.opened ?
            <div className={'camerasList'}>{this.props.place.rooms.map(room => {
                return room.cameras.map(cam => {

                    return <CameraCheckbox key={'camera_'+cam.id} isChecked={(this.props.isChecked[room.id][cam.id])} data={cam} callback={(value)=>{
                        let items = this.props.isChecked;
                        items[room.id][cam.id] = value;
                        let checked;
                        if (value) {
                            checked = true;
                            for (let camera_id in items[room.id]) {
                                if (items[room.id][camera_id] === false) checked = false;
                            }
                        } else {
                            checked = false;
                        }
                        this.setState({checked: checked})
                    }
                    }/>
                })
            })}</div>
            : null;

        return <div className="checkbox-item-list"><label htmlFor={'place-' + id} className="form-item-checkbox">
            <input type="checkbox" name={'place-' + id} id={'place-' + id} checked={this.state.checked} onChange={() => {
                let newCheck = !this.state.checked;
                for (let rid in this.props.isChecked) {
                    for (let cid in this.props.isChecked[rid]) {
                        this.props.isChecked[rid][cid] = newCheck;
                    }
                }
                this.setState({checked: newCheck})
            }}/>
            <span className="like-checkbox"/>
            {label}
        </label>
            <span className={'form-list-tree-toggle'} onClick={()=>{this.setState({opened: !this.state.opened})}}>{this.state.opened?strings.close:strings.open}</span>
            {cams}
        </div>
    }
}


class CameraCheckbox extends Component {

    render() {
        let id = this.props.data.id;
        let label = this.props.data.label;
        return <label htmlFor={'place-' + id} className="form-item-checkbox">
            <input type="checkbox" name={'place-' + id} id={'place-' + id} checked={this.props.isChecked} onChange={(elm) => {
                this.props.callback(elm.target.checked)
            }}/>
            <span className="like-checkbox"/>
            {label}
        </label>
    }
}
