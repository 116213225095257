import {Component} from "react";
import {Link} from "react-router-dom";
import Loader from "../../libraries/Loader/Loader";
import {toast} from "react-toastify";
import TorlinModal from "../../libraries/TorlinModal/TorlinModal";
import QRCode from "react-qr-code";

export default class MobileApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: null,
        }
        this.callbackOpen = this.callbackOpen.bind(this);
        this.callbackClose = this.callbackClose.bind(this);
    }


    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        this.props.api.getMobileDevices().then(res => {
            if (res.count) {
                let data = res.data;
                data.map(item => {
                    item.open = false
                    return null;
                })
                this.setState({data: data, loaded: true});
            } else {
                this.setState({loaded: true});
            }
        })
    }


    render() {
        let content = null;
        if (this.state.loaded === false) {
            content = <Loader/>
        } else {
            content = this.renderContent();
        }

        return (<>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to={'/'}>Domů</Link></li>
                    <li><span>Mobilní aplikace</span></li>
                </ul>
            </div>
            <div className={'mobileapp'}>
                {content}
                <div className="mobileapp-install">
                    <h2>Ještě nemáte mobilní aplikaci TORLIN Alarm?</h2>

                    <div className="mobileapp-install-phone"><i></i></div>
                    <div className="mobileapp-install-apps">
                        <div className="item">
                            <i className="ico-gplay"></i>
                            <div className="item-img"><QRCode size={112} value={'https://play.google.com/store/apps/details?id=cz.headsoft.torlinalert'}/></div>
                            <p>Verzi pro Android stahujte <a
                                href="https://play.google.com/store/apps/details?id=cz.headsoft.torlinalert"
                                className="">zde</a></p>
                        </div>
                        <div className="item">
                            <i className="ico-ios"></i>
                            <div className="item-img"><QRCode size={112} value={'https://apps.apple.com/sn/app/torlin-alarm/id1561833911#?platform=iphone'}/></div>
                            <p>Verzi pro Apple stahujte <a
                                href="https://apps.apple.com/sn/app/torlin-alarm/id1561833911#?platform=iphone"
                                className="">zde</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }


    renderContent() {
        if (this.state.data) {
            return <MobileAppDevice api={this.props.api} data={this.state.data} callbackOpen={this.callbackOpen} callbackClose={this.callbackClose}/>
        } else {
            return <MobileAppEmpty/>
        }
    }

    saveOpenStatus(id, status) {
        let data = this.state.data;
        data.map(item => {
            if (item.id === id) {
                item.open = status;
            }
            return null;
        })
        this.setState({data: data})
    }

    callbackOpen(id) {
        this.saveOpenStatus(id, true);
    }


    callbackClose(id) {
        this.setState({loaded: false})
        this.refreshData();
        this.saveOpenStatus(id, false);
    }
}

class MobileAppEmpty extends Component {
    render() {
        return (
            <div className="mobileapp-content">
                <h1>Moje zařízení</h1>

                <div className="mobileapp-list">
                    <p className="p-empty">Zatím nevyužíváte mobilní aplikaci v žádném zařízení</p>
                </div>

                <a href="https://apps.apple.com/sn/app/torlin-alarm/id1561833911#?platform=iphone"
                   className="btn-download"><i className="ico-ios"/>Stáhnout z App Store</a>
                <a href="https://play.google.com/store/apps/details?id=cz.headsoft.torlinalert"
                   className="btn-download"><i className="ico-gplay"/>Stáhnout z Google Play</a>
            </div>
        )
    }
}

class MobileAppDevice extends Component {
    render() {
        return (
            <div className="mobileapp-content">
                <h1>Moje zařízení</h1>

                <div className="mobileapp-list">
                    <ul>
                        {this.props.data.map(item => {
                            if (item.open === false) {
                                return (
                                    <li key={"mobile_device_id_" + item.id}>
                                        <div className="item-head" onClick={() => {
                                            this.props.callbackOpen(item.id)
                                        }}>
                                            <h3>{item.label}</h3>
                                            <span>editovat</span>
                                        </div>
                                    </li>
                                )
                            } else {
                                return <li key={"mobile_device_id_" + item.id}>
                                    <MobileAppDeviceOpen saveCallback={()=>this.props.callbackClose(item.id)} api={this.props.api} item={item}/>
                                </li>
                            }
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

class MobileAppDeviceOpen extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemoveDevice = this.handleRemoveDevice.bind(this);

        this.state = {
            label: this.props.item.label,
            alerts: this.props.item.alerts,
            showModalConfirmDelete: false,
        }
    }


    render() {
        let item = this.props.item;
        return <div className="mobileapp-item-content">
            <div className="item-name">
                <input type="text" onChange={(element)=>{
                    this.setState({label: element.target.value})
                }} value={this.state.label}/>
            </div>

            <div className="item-hash">
                <span>ID: {item.token}</span>
            </div>

            <h4>Objekty:</h4>
            <div className="item-object-list">
                {this.state.alerts.map(alert => {
                    return <label key={'alert_'+item.id+'_'+alert.id} htmlFor={'alert_'+item.id+'_'+alert.id}>
                        <input type="checkbox" name={'alert_'+item.id+'_'+alert.id} id={'alert_'+item.id+'_'+alert.id} onChange={e=>this.updateActivated(alert.id, e.target.checked)} defaultChecked={alert.activated}/>
                        <span className="like-checkbox"/>
                        {alert.name}
                    </label>
                })}
            </div>
            <div className="item-footer">
                <button onClick={()=>{this.setState({showModalConfirmDelete: true})}} className="btn-remove">Odstranit zařízení</button>
                <button onClick={this.handleSubmit} type="submit" className="btn-save">Uložit</button>
            </div>
            <TorlinModal open={this.state.showModalConfirmDelete}
                         cancelCallback={()=>{this.setState({showModalConfirmDelete: false})}}
                         callback={this.handleRemoveDevice}
                         text={'Opravdu chcete zařízení "'+item.label+'" odstranit?'}
                         confirmText={'Odstranit'}
            />
        </div>
    }


    updateActivated(alert_id, checked) {
        let alerts = this.state.alerts;
        for (let index in alerts) {
            if (!alerts.hasOwnProperty(index)) continue;
            if (alerts[index].id === alert_id) {
                alerts[index].activated = checked;
                break;
            }
        }
        this.setState({alerts: alerts})
    }


    handleSubmit() {
        let alerts = this.state.alerts;
        let activated = [];
        for (let alert_id in alerts) {
            if (!alerts.hasOwnProperty(alert_id)) continue;
            if (alerts[alert_id].activated) {
                activated.push(alerts[alert_id].id);
            }
        }
        this.props.api.saveMobileDevice(this.props.item.id, this.state.label, activated).then(res=>{
            if (res.status === 'ok') {
                toast.success("Nastavení zařízení bylo aktualizováno.")
                this.props.saveCallback();
            } else {
                toast.error(res.message);
            }
        })
    }


    handleRemoveDevice() {
        this.props.api.deleteMobileDevice(this.props.item.id).then(res=>{
            if (res.status === 'ok') {
                this.setState({showModalConfirmDelete: false});
                toast.success("Zařízení \"" + this.props.item.label + "\" bylo odstraněno.")
                this.props.saveCallback();
            } else {
                toast.error(res.message);
            }
        });
    }
}

