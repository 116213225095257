import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import {toast} from "react-toastify";
import Select from "react-select";
import {reactSelectDarkTheme} from "../../libraries/ReactSelect/DarkTheme";
import LocalizedStrings from "react-localization";
import {Circle, Layer, Line, Shape, Stage} from "react-konva";
import TorlinModal from "../../libraries/TorlinModal/TorlinModal";

let strings = new LocalizedStrings({
    en: {
        neuronSelectPlaceholder: 'Select neuron',
        roomSelectPlaceholder: 'Select room',
        roiSelectPlaceholder: 'Select Roi',
        changeRoiPosition: 'Change ROI Position',
        roiChangedSuccessfully: 'Position of Roi has been changed.',
        roiDidNotChange: 'ROI did not changed.',
        deleteRoi: 'Delete ROI',
        confirmToDeleteROI: 'Are you sure you want to delete this ROI?',
        addNewROI: 'Add new ROI',
        nameOfNewRoi: 'Name of new ROI'
    },
    cs: {
        neuronSelectPlaceholder: 'Vyberte neuron',
        roomSelectPlaceholder: 'Vyberte místnost',
        roiSelectPlaceholder: 'Vyberte oblast',
        changeRoiPosition: 'Potvrdit změnu oblasti',
        roiChangedSuccessfully: 'Změna oblasti proběhla úspěšně',
        roiDidNotChange: 'Změnit oblast se nepovedlo.',
        deleteRoi: 'Smazat Oblast',
        confirmToDeleteROI: 'Jste si jisti, že chcete smazat tuto Oblast?',
        addNewROI: 'Vytvořit novou Oblast',
        nameOfNewRoi: 'Název nové oblasti'

    }
})


export default class RoiSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            tree: [],
            neuronSelected: null,
            roomList: [],
            roomSelected: null,
            roiSelected: null,
            roiList: [],
            footage: [],
            roiNotChanged: true,
            confirmDelete: false,
            openNewRoiModal: false,
            newRoiNameValue: '',
        }
        this.changeNeuron = this.changeNeuron.bind(this);
        this.changeRoom = this.changeRoom.bind(this);
        this.callbackChangeRoi = this.callbackChangeRoi.bind(this);
        this.getNewRoiHtml = this.getNewRoiHtml.bind(this);
    }

    componentDidMount() {
        this.updateRoiTree();
    }

    updateRoiTree(callback) {
        this.props.api.getRoiSettingsTree().then(res=>{
            if (res.status === 'ok') {
                let roi_list = [];
                if (this.state.roomSelected) {
                    let current_room_id = this.state.roomSelected.id;
                    for (let i=0;i<res.tree.length;i++) {
                        for (let j=0;j<res.tree[i].rooms.length;j++) {
                            if (res.tree[i].rooms[j].id === current_room_id) {
                                roi_list = res.tree[i].rooms[j].rois;
                                break;
                            }
                        }
                        if (roi_list.length) break;
                    }
                }
                this.setState({
                    loaded: true,
                    tree: res.tree,
                    roiList: roi_list,
                }, callback)
            } else {
                toast.error(res.message);
            }
        });
    }

    changeRoom(val) {
        let newState = {roomSelected: val}
        if (val.rois.length === 1) {
            newState.roiSelected = val.rois[0];
        } else {
            newState.roiSelected = null;
        }
        this.setState(newState);
        this.props.api.getFootage(val.id).then(res=>this.setState({footage: res.footage}));
    }

    changeNeuron(val) {
        let newState = {neuronSelected: val}
        newState.roomList = val.rooms;
        if (val.rooms.length === 1) {
            newState.roomSelected = val.rooms[0];
            newState.roiList = val.rooms[0].rois
            if (val.rooms[0].rois.length === 1) {
                newState.roiSelected = val.rooms[0].rois[0];
            }
            this.props.api.getFootage( val.rooms[0].id).then(res=>this.setState({footage: res.footage}));
        } else {
            newState.roomSelected = null
            newState.roiList = []
            newState.roiSelected = null
        }
        this.setState(newState)
    }


    callbackChangeRoi(newParameters) {
        let old = this.state.roiSelected;
        for (let index in newParameters) {
            old[index] = newParameters[index];
        }
        this.setState({roiSelected: old,roiNotChanged: false});
    }

    getNewRoiHtml() {
        return <>
            <h3>{strings.addNewROI}</h3>
            <div className={'modal-form'}><label>{strings.nameOfNewRoi}: </label>
                <input onChange={(elm=>this.setState({newRoiNameValue: elm.target.value}))}/>
            </div>
        </>;
    }

    render() {
        if (this.state.loaded === false) {
            return <Loader />
        }
        let rooms = null;
        if (this.state.neuronSelected) {
            rooms = <div><Select
                placeholder={strings.roomSelectPlaceholder}
                styles={reactSelectDarkTheme}
                className={'react-select'}
                value={this.state.roomSelected}
                onChange={this.changeRoom}
                options={this.state.roomList} />
            </div>
        }
        let rois = null;
        let dimension = null;
        if (this.state.roomSelected) {
            rois = <div><Select
                placeholder={strings.roiSelectPlaceholder}
                styles={reactSelectDarkTheme}
                className={'react-select'}
                value={this.state.roiSelected}
                onChange={val=>this.setState({roiSelected: val})}
                options={this.state.roiList} />
            </div>
            dimension = this.state.roomSelected.dimension;
        }
        return (<div className={'RoiSettingFilter'}>
            <div>
                <Select
                    placeholder={strings.neuronSelectPlaceholder}
                    styles={reactSelectDarkTheme}
                    className={'react-select'}
                    value={this.state.neuronSelected}
                    onChange={this.changeNeuron}
                    options={this.state.tree} />
            </div>
                {rooms}
                {rois}
                {(this.state.roomSelected)?
                    <div className={'roi-buttons'}>
                        <button className={'btn-blue-inline'}
                                onClick={()=>{this.setState({openNewRoiModal: true})}}>{strings.addNewROI}</button>
                        <TorlinModal
                            open={this.state.openNewRoiModal}
                            text={strings.confirmToDeleteROI}
                            html={this.getNewRoiHtml()}
                            callback={()=>{
                                this.props.api.createNewRoi(this.state.roomSelected.id, this.state.newRoiNameValue).then(res=>{
                                    if (res.status === 'OK') {
                                         this.updateRoiTree(()=>{
                                             this.setState({roiSelected: res.newRoi,openNewRoiModal: false})
                                         })
                                    } else {
                                        toast.error(res.message)
                                    }
                                })
                            }} cancelCallback={()=>{
                            this.setState({openNewRoiModal: false})
                        }}/>
                        {(this.state.roiSelected) ?<>
                            <button className={'btn-blue-inline'}
                            onClick={()=>{this.props.api.updateRoi(this.state.roiSelected).then(res=>{
                                if(res.status === 'OK') {
                                    toast.success(strings.roiChangedSuccessfully)
                                } else {
                                    toast.error(strings.roiDidNotChange)
                                }})}}
                            disabled={this.state.roiNotChanged}>{strings.changeRoiPosition}</button>
                        <button className={'btn-red-inline'}
                            onClick={()=>{this.setState({confirmDelete: true})}}>{strings.deleteRoi}</button>
                        <TorlinModal
                            open={this.state.confirmDelete}
                            text={strings.confirmToDeleteROI}
                            callback={()=>{
                                this.props.api.deleteRoi(this.state.roiSelected).then(res=>{
                                    if (res.status === 'OK') {
                                        this.setState({
                                            roiSelected: null,confirmDelete: false,
                                        })
                                        this.updateRoiTree();
                                    } else {
                                        toast.error(res.message)
                                    }
                                })
                            }} cancelCallback={()=>{
                                this.setState({confirmDelete: false})
                        }}/></>
                            :null}
                    </div>
                    :null}
                <RoiDrawing dimensions={dimension} rois={this.state.roiSelected} footage={this.state.footage} changeRoi={this.callbackChangeRoi} />
            </div>
        );
    }
}

class RoiDrawing extends Component {

    drawRoomDimension() {
        return <Shape
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(this.props.dimensions[0].x, this.props.dimensions[0].y)
                for (let i=1;i<this.props.dimensions.length;i++) {
                    context.lineTo(this.props.dimensions[i].x,this.props.dimensions[i].y)
                }
                context.closePath();
                context.fillStrokeShape(shape);
            }}
            stroke={'#fff'}
            strokeWidth={2}
            />
    }

    drawRoiRectangle() {
        if (this.props.rois) {
            return <>
                <Line
                x={0}
                y={0}
                points={[this.props.rois.x11,this.props.rois.y11, this.props.rois.x12, this.props.rois.y12]}
                stroke={"green"}
                lineJoin={'square'}
            /><Line
                x={0}
                y={0}
                points={[this.props.rois.x21,this.props.rois.y21, this.props.rois.x22, this.props.rois.y22]}
                stroke={"red"}
            />
                <Circle draggable={true}
                        onDragMove={e=>{if (e.target.attrs.x<5 || e.target.attrs.y<5) { e.target.stopDrag();}}}
                        onDragEnd={e=>{this.props.changeRoi({x11: e.target.attrs.x, y11: e.target.attrs.y})}}
                        x={this.props.rois.x11} y={this.props.rois.y11} radius={5} fill={"green"}/>

                <Circle draggable={true}
                    onDragMove={e=>{if (e.target.attrs.x<5 || e.target.attrs.y<5) { e.target.stopDrag();}}}
                    onDragEnd={e=>{this.props.changeRoi({x12: e.target.attrs.x, y12: e.target.attrs.y})}}
                    x={this.props.rois.x12} y={this.props.rois.y12} radius={5} fill={"green"}/>
                <Circle draggable={true}
                    onDragMove={e=>{if (e.target.attrs.x<5 || e.target.attrs.y<5) { e.target.stopDrag();}}}
                    onDragEnd={e=>{this.props.changeRoi({x21: e.target.attrs.x, y21: e.target.attrs.y})}}
                    x={this.props.rois.x21} y={this.props.rois.y21} radius={5} fill={"red"}/>
                <Circle draggable={true}
                    onDragMove={e=>{if (e.target.attrs.x<5 || e.target.attrs.y<5) { e.target.stopDrag();}}}
                    onDragEnd={e=>{this.props.changeRoi({x22: e.target.attrs.x, y22: e.target.attrs.y})}}
                    x={this.props.rois.x22} y={this.props.rois.y22} radius={5} fill={"red"}/>
            </>
        }
    }

    drawFootage() {
        let lines = []; let movement;
        for (let pid in this.props.footage) {
            movement = this.props.footage[pid];
            let points = [];
            for (let i=0;i<movement.length;i++) {
                if (i===0) {
                    lines.push(<Circle x={movement[i].x} y={movement[i].y} radius={2} fill={"green"}/>);
                }
                points.push(movement[i].x, movement[i].y);
                if (i === (movement.length-1)) {
                    lines.push(<Circle x={movement[i].x} y={movement[i].y} radius={2} fill={"red"}/>);
                }
            }
            lines.push(<Line x={0} y={0} points={points} stroke={"white"} strokeWidth={0.5} opacity={0.5}/>)
        }
        return <>{lines}</>
    }

    render() {
        if (this.props.dimensions) {
            let width = 0;
            let height = 0;
            for (let i=0;i<this.props.dimensions.length;i++) {
                width = Math.max(width, this.props.dimensions[i].x)
                height = Math.max(height, this.props.dimensions[i].y)
            }
            return <Stage className={'roi-dimension'} width={width} height={height}>
                <Layer>
                    {this.drawRoomDimension()}
                    {this.drawFootage()}
                </Layer>
                <Layer>
                    {this.drawRoiRectangle()}
                </Layer>
            </Stage>
        } else {
            return null;
        }
    }
}
