import {Component} from "react";
import LoginForm from "./LoginForm";

export default class Login extends Component {
    render() {
        return (
            <div className={"page page-login"}>
                <div className={"login-content"}>
                    <h1>TORLIN by Headsoft</h1>
                    <LoginForm api={this.props.api} />
                </div>
            </div>
        )
    }
}
