import {Component} from "react";
import {Link} from "react-router-dom";
import LocalizedStrings from "react-localization";
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css';

let strings = new LocalizedStrings({
    en: {
        addFav: 'add to favorites'
    },
    cs: {
        addFav: 'přidat do oblíbených'
    }
})

videojs.addLanguage('cs', {
    'LIVE': 'ŽIVĚ',
    'Pause': 'Zastavit',
    'Playback Rates': 'Rychlost přehrávání',
    'Picture-in-Picture': 'Obraz v obraze',
    'Fullscreen': 'Přes celou obrazovku'
})


export default class CameraCell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            favorite: this.props.data.favorite,
            status: 'standby',
            url: null,
            width: 0,
            height: 0
        }

        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.loadOnlineStream = this.loadOnlineStream.bind(this);
    }


    componentDidMount() {
        let width = document.getElementById('border-for-'+this.props.data.id).clientWidth
        let height = document.getElementById('border-for-'+this.props.data.id).clientHeight
        this.setState({width: width, height: height})
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.player === undefined && this.videoNode) {
            this.initPlayer();
        }
    }


    initPlayer() {
        let cameraCellObject = this;
        this.player = videojs(this.videoNode, {
            sound: false,
            controls: true,
            muted: true,
            // autoplay: true,
            language: this.props.api.user.language,
            width: this.state.width,
            height: this.state.height,
            poster: this.props.data.image,
            html5: {
                nativeAudioTrack: false,
                nativeVideoTracks: false
            },
            controlBar: {
                muteToggle: false,
                volumePanel: false,
                fullscreenToggle: true,
            },
            sources: [{
                // src: 'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8',
                src: this.state.url,
                type: 'application/x-mpegURL',
                withCredentials: true
            }],
            // playsinline: true,
        }, function onPlayerReady() {
            cameraCellObject.startKeepAlive();
            this.play();
        });
    }


    startKeepAlive() {
        this.keepAlive = setInterval(()=>{
            this.props.api.keepAlive(this.props.data.id);
        }, 5000);
    }


    stopKeepAlive() {
        if (this.keepAlive) {
            clearInterval(this.keepAlive);
        }
    }


    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
        this.stopKeepAlive()
    }


    toggleFavorite() {
        let new_state = !this.state.favorite;
        this.props.favCallback(this.props.data, new_state)
        if (new_state) this.setState({favorite: new_state})
    }


    loadOnlineStream() {
        this.setState({status: 'loading'});
        this.props.api.getOnlineStream(this.props.data.id).then(res => {
            if (res.status === 'ERROR') {
                this.setState({status: 'error', ErrorMessage: res.message})
            } else if (res.url) {
                this.setState({status: 'playing', url: res.url})
            }
        })
    }


    render() {
        let content = '';
        if (this.state.status === 'standby') {
            content = <div id={'border-for-'+this.props.data.id} onClick={this.loadOnlineStream} className="camera-content">
                <img src={this.props.data.image} alt={this.props.data.label}/>
            </div>
        } else if (this.state.status === 'loading') {
            content = <div className="camera-content-loading">
                <img src={this.props.data.image} alt={this.props.data.label}/>
            </div>
        } else if (this.state.status === 'playing') {
            content = <div className={'camera-content-playing'} data-vjs-player={true}>
                <video ref={ node => this.videoNode = node } className="video-js" />
            </div>
        } else if (this.state.status === 'error') {
            content = <div id={'border-for-'+this.props.data.id} onClick={this.loadOnlineStream} className="camera-content error">
                <div className={'message'}>{this.state.ErrorMessage}</div>
            </div>
        }
        return (
            <div className="camera-list-item">
                <div className="camera">
                    <div className="camera-head">
                        <h3><Link to={'cams/'+this.props.data.id}>{this.props.data.label}</Link></h3>
                        <div className="camera-head-action">
                            <Link to={'cams/'+this.props.data.id} className="btn-detail">detail</Link>
                            <button onClick={this.toggleFavorite}
                                    className={"btn-fav" + (this.state.favorite ? " active" : "")}>{strings.addFav}</button>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        )
    }
}
