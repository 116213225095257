import {Component} from "react";
import Breadcrumbs from "../../libraries/Breadcrumbs/Breadcrumbs";
import LocalizedStrings from "react-localization";
import SelectFromList from "../../libraries/SelectFromList/SelectFromList";
import CamDetailRightPanel from "./CamDetailRightPanel";
import videojs from "video.js";
import {Redirect} from 'react-router-dom'
import Loader from "../../libraries/Loader/Loader";

let strings = new LocalizedStrings({
    en: {
        object: 'Object',
        camera: 'Camera',
        live: 'Live',
        cutStart: 'From',
        cutTill: 'Till',
        download: 'Download'
    },
    cs: {
        object: 'Objekt',
        camera: 'Kamera',
        live: 'Živě',
        cutStart: 'Začátek',
        cutTill: 'Konec',
        download: 'Stáhnout'
    }
})

export default class CamDetail extends Component {
    constructor(props) {
        super(props);
        this.state = this.getDefaultStates();
        this.id = Number(this.props.match.params.id);
        this.changeTimeline = this.changeTimeline.bind(this);
        this.askApiToPlaylist = this.askApiToPlaylist.bind(this);
        this.moveOnTimeLine = this.moveOnTimeLine.bind(this);
        this.changeCs = this.changeCs.bind(this);
        this.changeCam = this.changeCam.bind(this);
        this.switchToLive = this.switchToLive.bind(this);
        this.switchToRecords = this.switchToRecords.bind(this);
        this.handleClickFavorite = this.handleClickFavorite.bind(this);
        this.setNewPlaylistToVideoPlayer = this.setNewPlaylistToVideoPlayer.bind(this);
        this.setDownloadTill = this.setDownloadTill.bind(this);
        this.setDownloadFrom = this.setDownloadFrom.bind(this);
        this.downloadRecords = this.downloadRecords.bind(this);
    }


    getDefaultStates() {
        return {
            loading: true,
            loading_live: false,
            playing_type: 'record',
            camera_info: {
                name: '',
                desc: ''
            },
            cs_info: {
                name: '',
                id: ''
            },
            other_cids: [],
            other_cams: [],
            cid_cams: [],
            redirect_id: null,
            live_url: null,
            isFavorite: false,
            errorSource: false,
            playlist_from: true,
            playlist_till: true,
            isDownloadDisabled: true,
            downloadFrom: null,
            downloadTill: null,
            downloadFromString: '--:--:--',
            downloadTillString: '--:--:--',
            downloadSize: 0,
            playerInitialized: false,
            previousTotalTime: 0,
            previousCurrentTime: 0,
        }
    }


    componentDidMount() {
        this.loadCamera();
    }


    loadCamera() {
        this.props.api.loadCameraInfo(this.id).then(res => {
            let other_cids = res.other_cids;
            let new_state_cids = [];
            let tmp; let cid_cams = []; let cams_id;
            let cameras;
            let new_state_cams = [];
            let cs_id = res.cs_info.id;
            for (let index in other_cids) {
                if (!other_cids.hasOwnProperty(index)) continue;
                tmp = other_cids[index];
                new_state_cids.push({id: tmp.id, label: tmp.label});
                cams_id = [];
                for (let room_index in tmp.rooms) {
                    if (!tmp.rooms.hasOwnProperty(room_index)) continue;
                    cameras = tmp.rooms[room_index].cameras;
                    for (let cam_index in cameras) {
                        if (!cameras.hasOwnProperty(cam_index)) continue;
                        if (tmp.id === cs_id) new_state_cams.push({id: cameras[cam_index].id, label: cameras[cam_index].label})
                        cams_id.push(cameras[cam_index].id);
                    }
                }

                cid_cams[tmp.id] = cams_id;
            }
            this.setState({
                camera_info: res.camera_info,
                cs_info: res.cs_info,
                loading: false,
                other_cids: new_state_cids,
                other_cams: new_state_cams,
                cid_cams: cid_cams,
                isFavorite: res.favorite,
                playerInitialized: false,
                file_sizes: []
            })
        })
    }


    changeCs(event) {
        let cs_id = event.target.value;
        let cam_id = this.state.cid_cams[cs_id].shift();
        this.redirectToCam(cam_id);
    }


    changeCam(event) {
        let cam_id = event.target.value;
        this.redirectToCam(cam_id);
    }


    redirectToCam(cam_id) {
        let new_state = this.getDefaultStates();
        new_state.previousTotalTime = this.player.duration();
        new_state.previousCurrentTime = this.player.currentTime();
        this.setState(new_state, ()=>{
            if (this.player) this.player.dispose();
            this.player = null;
            this.setState({redirect_id: null})
            this.id = cam_id;
            this.loadCamera();
            this.stopKeepAlive();
        })
    }


    moveOnTimeLine(time) {
        if (!this.player) return;
        let date = new Date(time); let move_time = 0; let i;
        date = date.getTime()/1000;
        for (i= 0;i<this.state.segments.length;i++) {
            let segment = this.state.segments[i];
            if (segment.start < date) {
                let diff = Number(date)-Number(segment.start);
                if (diff<Number(segment.length)) {
                    move_time += (diff-5);
                    break;
                } else {
                    move_time += Number(segment.length);
                }
            }
        }
        this.player.currentTime(move_time);
    }


    askApiToPlaylist() {
        let from = this.playlist_from;
        let till = this.playlist_till;
        this.setState({playlist_from: from, playlist_till: till, playerInitialized: false});

        let current_object = this;
        if (!from) return;

        this.props.api.getRecordPlaylist(this.id, from, till).then(res => {
            if (res.playlist) {
                current_object.setNewPlaylistToVideoPlayer(res.playlist.url)
                this.setState({file_sizes: res.playlist.file_sizes, segments: res.playlist.segments})
            }
            else {
                this.setState({errorSource: true})
            }
        });
    }


    setNewPlaylistToVideoPlayer(url) {
        if (this.player) {
            let player = this.player;
            player.src(url);
            this.setState({playerInitialized: true})
        } else {
            let options = {
                sound: false,
                controls: true,
                muted: true,
                autoplay: true,
                // width: 1140,
                // height: 715,
                language: this.props.api.user.language,
                controlBar: {
                    muteToggle: false,
                    volumePanel: false,
                    fullscreenToggle: true,
                },
                playbackRates: [0.5, 1, 2,4,8,16],
                playsinline: true,
            };
            if (url === undefined) {
                options.preload = false;
            } else {
                options.sources = [{
                    src: url,
                    withCredentials: true
                }];
            }
            this.player = videojs(this.videoNode, options , function onPlayerReady() {
                object_this.setState({playerInitialized: true})
            })
            let object_this = this;
            this.player.on('error', ()=>{
                // this.player.dispose();
                if ( !((this.state.playing_type === 'live') && (url === undefined))) {
                    object_this.setState({errorSource: true})
                }
            });
            this.player.on('loadedmetadata', ()=>{
                if (this.state.previousCurrentTime) {
                    if (this.state.playing_type === 'record' && Math.abs(this.state.previousTotalTime-this.player.duration())<(this.player.duration()/10)) {
                        this.player.currentTime(this.state.previousCurrentTime);
                    }
                    this.setState({previousCurrentTime: 0,previousTotalTime: 0})
                }
            })
        }
    }


    changeTimeline(from, till) {
        this.playlist_from = from;
        this.playlist_till = till;

        if (this.player && !this.player.isDisposed()) {
            this.player.pause();
            this.player.addClass('vjs-waiting');
            if (this.askPlaylistTimeout) {
                clearTimeout(this.askPlaylistTimeout);
            }
            this.askPlaylistTimeout = setTimeout(this.askApiToPlaylist, 500);
        } else {
            this.askApiToPlaylist();
        }
    }


    switchToLive() {
        this.setState({playing_type: 'live', errorSource: false},()=>{
            if (this.player) {
                this.record_tmp_playlist = this.player.src();
                this.player.pause();
                this.player.addClass('vjs-waiting');
            } else {
                this.setNewPlaylistToVideoPlayer();
                this.player.addClass('vjs-waiting');
            }
        });
        this.props.api.getOnlineStream(this.id).then(res => {
            if (res.url) {
                this.setState({loading_live: false, live_url: res.url})
                this.setNewPlaylistToVideoPlayer(res.url)
                this.startKeepAlive();
            }
        })
    }


    switchToRecords() {
        this.setState({playing_type: 'record'});
        this.player.pause();
        this.player.addClass('vjs-waiting');
        this.stopKeepAlive();
        if (this.record_tmp_playlist) {
            this.player.src(this.record_tmp_playlist);
        } else {
            this.player.dispose();
            this.player = null;
            this.setState(this.getDefaultStates(), ()=>{this.loadCamera()});
        }
    }

    startKeepAlive() {
        this.keepAlive = setInterval(()=>{
            this.props.api.keepAlive(this.id);
        }, 5000);
    }


    stopKeepAlive() {
        if (this.keepAlive) {
            clearInterval(this.keepAlive);
        }
    }


    disableRecord() {
        this.setState({isDownloadDisabled: true, downloadFrom: null, downloadTill: null})
    }


    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
        this.stopKeepAlive()
    }


    handleClickFavorite() {
        let new_state = !this.state.isFavorite;
        if (new_state) {
            this.props.api.addFavCam(this.id);
        } else {
            this.props.api.removeFavCam(this.id)
        }
        this.setState({isFavorite: new_state});
    }

    getCurrentSegment() {
        if (!this.player) return null;
        let segments = this.state.segments;
        let current = this.player.currentTime();

        let length = 0;
        for (let i=0; i<segments.length; i++) {
            length += Number(segments[i].length);
            if (current<=length) {
                return {
                    start: Number(segments[i].start),
                    duration: Number(segments[i].length),
                    position: i,
                    url: segments[i].url
                };
            }
        }
    }

    getDownloadTimeString(timestamp) {
        let dateObject = new Date(timestamp * 1000);
        return ("0" + dateObject.getHours()).slice(-2) + ":" + ("0" + dateObject.getMinutes()).slice(-2) + ":" + ("0" + dateObject.getSeconds()).slice(-2)
    }


    getCalculatedDownloadSize(from, till) {
        if (!this.player) return 0;
        let segments = this.state.file_sizes;
        if (segments.length<till) return 0;
        if (till<from) return 0;
        let result = 0;
        for (let i=from;i<=till;i++) {
            result += Number(segments[i]);
        }
        return result;
    }


    setDownloadFrom() {
        let segment = this.getCurrentSegment();
        let event_time = segment.start;
        let time_string = this.getDownloadTimeString(event_time);
        let isDownloadDisables = (this.state.downloadTill===null)
        let size =  (isDownloadDisables === false) ? this.getCalculatedDownloadSize(segment.position, this.state.downloadTill) : 0;
        this.setState({downloadFrom: segment.position, downloadFromString: time_string, isDownloadDisabled: isDownloadDisables, downloadSize: size})
    }

    setDownloadTill() {
        let segment = this.getCurrentSegment();
        let event_time = segment.start+segment.duration;
        let time_string = this.getDownloadTimeString(event_time);
        let isDownloadDisables = (this.state.downloadFrom===null)
        let size =  (isDownloadDisables === false) ? this.getCalculatedDownloadSize(this.state.downloadFrom, segment.position) : 0;
        this.setState({downloadTill: segment.position, downloadTillString: time_string, isDownloadDisabled: isDownloadDisables, downloadSize: size})
    }

    downloadRecords() {
        if ( (this.state.downloadFrom!==null) && (this.state.downloadTill!==null)) {
            let segments = this.state.segments;
            let from = segments[this.state.downloadFrom].url;
            let till = segments[this.state.downloadTill].url;
            let from_file = from.substr(from.indexOf('file=')+5);
            let till_file = till.substr(till.indexOf('file=')+5);
            this.props.api.downloadRecords(from_file, till_file).then(res=> {
                const element = document.createElement('a');
                element.href = window.URL.createObjectURL(res.data.slice(0, res.data.size, 'application/octet-stream'));
                document.body.appendChild(element);
                element.download = this.state.cs_info.name + '_'+this.state.camera_info.name + '_' + this.state.downloadFromString + '-' + this.state.downloadTillString + '.mp4';
                element.click();
                element.parentNode.removeChild(element);
            });
        }
    }


    render() {
        if (this.state.redirect_id) {
           return <Redirect to={'/cams/'+this.state.redirect_id} />
        } else if (this.state.loading) {
            return <Loader />
        } else {
            let record_div = (this.state.playlist_from||(this.state.playing_type==='live'))
                ?
                <div data-vjs-player={true}><video ref={node => this.videoNode = node} className="video-js"/></div>
                :
                <div className="record-player-error"><p>Tato kamera nemá žádné záznamy.<br/>Pokud je to chyba, prosíme, kontaktujte naši technickou podporu.</p></div>

            return <div>
                <Breadcrumbs data={this.state.cs_info.name}/>
                <div className="record">
                    <div className="record-content">
                        <div className="record-head">
                            <div className="record-head-item">
                                <h2>{strings.object}:</h2>
                                <SelectFromList data={this.state.other_cids} selected={this.state.cs_info.id} onSelect={this.changeCs}/>
                            </div>
                            <div className="record-head-item">
                                <h2>{strings.camera}:</h2>
                                <SelectFromList data={this.state.other_cams} selected={this.id} onSelect={this.changeCam}/>
                            </div>
                            <div className="record-head-action">

                                <div className="camdetail-switch">
                                    <button onClick={this.switchToLive} className={'btn-switch-live'+(this.state.playing_type==='live'?' active':'')}>{strings.live}</button>
                                    <button onClick={this.switchToRecords} className={'btn-switch-record'+(this.state.playing_type==='record'?' active':'')}>Záznam</button>
                                </div>
                                <button onClick={this.handleClickFavorite} className={"btn-fav"+(this.state.isFavorite?' active':'')}>{strings.addFav}</button>
                            </div>
                        </div>
                        <div id={"record-player"} className="record-player">
                            {/*{this.state.errorSource?<div>asdf</div>:null}*/}
                            {this.state.errorSource?<div className="record-player-error"><p>Přípojení ke kameře se nezdařilo, <a href={window.location}>zkusit znovu</a>.<br/>Pokud problém přetrvává, kontaktujte naši technickou podporu.</p></div>:null}
                            {record_div}
                        </div>
                        {(this.state.playlist_from&&(this.state.playing_type!=='live')&&this.state.playerInitialized)?<div className="record-clip">
                            <div className="record-clip-editor">
                                <div className="clip-start">
                                    <button onClick={this.setDownloadFrom}>{strings.cutStart}<i></i></button>
                                    <input type="text" name="clipstart" id="clipstart" value={this.state.downloadFromString} readOnly={true}/>
                                </div>
                                <div className="clip-end">
                                    <input type="text" name="clipend" id="clipend" value={this.state.downloadTillString} readOnly={true}/>
                                    <button htmlFor="clipend" onClick={this.setDownloadTill}><i></i>{strings.cutTill}</button>
                                </div>
                            </div>
                            <div className="record-clip-settings">
                                <div className="record-clip-settings-params">
                                    <p className="clipsettings-name"><strong>{this.state.cs_info.name}: </strong>{this.state.camera_info.name}</p>
                                    <p className="clipsettings-size">{Math.round(this.state.downloadSize/1024/1024*100)/100} MB</p>
                                </div>
                                <button type="button" className="btn-download-ico"
                                        onClick={this.downloadRecords}
                                        disabled={this.state.isDownloadDisabled}>{strings.download}</button>
                            </div>
                        </div>:null}
                    </div>
                    <CamDetailRightPanel camera_id={this.id} api={this.props.api} callbackChangeTimeline={this.changeTimeline} callbackMoveOnTimeLine={this.moveOnTimeLine}
                                         from={this.playlist_from} till={this.playlist_till}
                    />
                </div>
            </div>
        }
    }
}
