import React, {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import {ResponsiveLine} from '@nivo/line'
import {ResponsiveSunburst} from "@nivo/sunburst";
import LocalizedStrings from "react-localization";
import TorlinTable from "../../libraries/TorlinTable/TorlinTable";
import {Collapse} from 'react-collapse'

let strings = new LocalizedStrings({
    en: {
        year: 'Year',
        month_01: 'January',
        month_02: 'February',
        month_03: 'March',
        month_04: 'April',
        month_05: 'May',
        month_06: 'June',
        month_07: 'July',
        month_08: 'August',
        month_09: 'September',
        month_10: 'October',
        month_11: 'November',
        month_12: 'December',
        season: 'Season',
        data_years: 'By years',
        data_months: 'By months',
        data_days: 'By days',
        data_weekday: 'Weekdays',
        day_0: 'Monday',
        day_1: 'Tuesday',
        day_2: 'Wednesday',
        day_3: 'Thursday',
        day_4: 'Friday',
        day_5: 'Saturday',
        day_6: 'Sunday',
    },
    cs: {
        year: 'Rok',
        month_01: 'Leden',
        month_02: 'Únor',
        month_03: 'Březen',
        month_04: 'Duben',
        month_05: 'Květen',
        month_06: 'Červen',
        month_07: 'Červenec',
        month_08: 'Srpen',
        month_09: 'Září',
        month_10: 'Říjen',
        month_11: 'Listopad',
        month_12: 'Prosinec',
        season: 'Období',
        data_years: 'Roky',
        data_months: 'Měsíce',
        data_days: 'Dny',
        data_weekday: 'Dny v týdnu',
        data_hours: 'Hodiny',
        day_0: 'Pondělí',
        day_1: 'Úterý',
        day_2: 'Středa',
        day_3: 'Čtvrtek',
        day_4: 'Pátek',
        day_5: 'Sobota',
        day_6: 'Neděle',
    }
})


export const theme = {
    "textColor": "white",
    "fontSize": 11,
    "axis": {
        "domain": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 1
            }
        },
        "ticks": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 1
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#2c2c2c",
            "strokeWidth": 1
        }
    },
    tooltip: {
        container: {
            background: 'black'
        }
    },
    label: {
        text: {
            fill: 'yellow'
        }
    }
}


export default class FootfallDataDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footfall_years: null,
            footfall_months: null,
            footfall_days: null,
            footfall_weekdays: null,
            footfall_hours: null,
            collapseYear: true,
            collapseMonth: false,
            collapseDay: false,
            collapseWeekday: false,
            collapseHour: false,
            date_from: this.props.from,
            date_till: this.props.till,
            rids: this.props.rids,
            loaded: false,
            widthAvailable: 500,
        }
        this.chartsRef = React.createRef()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.date_from && this.state.date_till && this.state.rids &&
            ((this.state.date_from.getTime() !== this.props.from.getTime()) || (this.state.date_till.getTime() !== this.props.till.getTime()) || (this.state.rids !== this.props.rids))) {
            this.setState({date_from: this.props.from, date_till: this.props.till, rids: this.props.rids}, ()=>{this.updateData()})
        }
    }

    componentDidMount() {
        this.updateData()
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateData() {
        this.props.api.getFootfallDetail(this.state.rids, this.state.date_from, this.state.date_till).then(res => {
            let chart_years = [];
            let chart_months = [];
            let chart_days = [];
            let chart_weekdays = {
                name: "weekdays",
                children: []
            };
            let chart_hours = [];
            for (let roi_id in res.data) {
                if (roi_id === 'weekday') {
                    let tmp; let tmp_year; let tmp_month;
                    let weekday_data = res.data[roi_id];
                    for (let week_day in weekday_data) {
                        let week_day_label = strings['day_'+week_day];
                        let tmp_weekday = {
                            name: week_day_label,
                            children: [],
                        }
                        let roi_data = weekday_data[week_day];
                        for (let real_roi_id in roi_data) {
                            let room_label = res.data[real_roi_id]['room_label'];
                            tmp = {
                                name: room_label+" - "+week_day_label,
                                children: [],
                                roi: real_roi_id
                            }
                            let year_data = roi_data[real_roi_id];
                            for (let year in year_data) {
                                tmp_year = {
                                    name: room_label+" - "+(year_data.length>1?year+" ":'')+week_day_label,
                                    children: [],
                                    roi: real_roi_id
                                }
                                let month_data = year_data[year];
                                for (let month in month_data) {
                                    tmp_month = {
                                        name: room_label+" - "+( (Object.keys(month_data).length>1)?strings['month_'+month]+", ":'')+(year_data.length>1?year+" ":' ')+week_day_label,
                                        footfall: month_data[month],
                                        roi: real_roi_id
                                    }

                                    tmp_year.children.push(tmp_month);
                                }
                                if (tmp_year.children.length===1) {
                                    tmp_year = tmp_month;
                                }
                                tmp.children.push(tmp_year);
                            }
                            if (tmp.children.length===1) {
                                tmp = tmp_year;
                            }
                            tmp_weekday.children.push(tmp);
                        }
                        if (tmp_weekday.children.length===1) {
                            tmp_weekday = tmp;
                        }
                        chart_weekdays.children.push(tmp_weekday)
                    }
                    continue;
                }
                let year_data = res.data[roi_id]['years'];
                let room_label = res.data[roi_id]['room_label'];
                let roi_label = res.data[roi_id]['label'];

                let tmp = {
                    id: room_label + ' - ' + roi_label,
                    color: "hsl(255, 70%, 50%)",
                    data: []
                }
                for (let year in year_data) {
                    tmp['data'].push({x: year, y: year_data[year]})
                }
                chart_years.push(tmp)

                let month_data = res.data[roi_id]['months'];

                let tmp_month = {
                    id: room_label + ' - ' + roi_label,
                    color: "hsl(255, 70%, 50%)",
                    data: []
                }
                for (let year in month_data) {
                    let months = Object.keys(month_data[year]).sort()
                    for (let month in months) {
                        month = months[month];
                        tmp_month['data'].push({x: strings['month_'+month]+' '+year, y: month_data[year][month]})
                    }
                }
                chart_months.push(tmp_month);

                let tmp_day = {
                    id: room_label + ' - ' + roi_label,
                    color: "hsl(255, 70%, 50%)",
                    data: []
                }
                let days_data = res.data[roi_id]['days'];
                for (let year in days_data) {
                    let months = Object.keys(days_data[year]).sort()
                    for (let month in months) {
                        month = months[month];
                        let days = Object.keys(days_data[year][month]).sort();
                        for (let day in days) {
                            day = days[day];
                            tmp_day['data'].push({x: day+'. '+strings['month_'+month]+' '+year, y: days_data[year][month][day]})
                        }
                    }
                }
                chart_days.push(tmp_day);
                let tmp_hour = {
                    id: room_label + ' - ' + roi_label,
                    color: "hsl(255, 70%, 50%)",
                    data: []
                }
                let hours_data = res.data[roi_id]['hour'];
                let tmp_hours = {};
                for (let year in hours_data) {
                    for (let month in hours_data[year]) {
                        for (let day in hours_data[year][month]) {
                            for (let hour in hours_data[year][month][day]) {
                                if (tmp_hours[hour] === undefined) {
                                    tmp_hours[hour] = hours_data[year][month][day][hour];
                                } else {
                                    tmp_hours[hour] += hours_data[year][month][day][hour];
                                }
                            }
                        }
                    }
                }
                let hours = Object.keys(tmp_hours).sort();
                for (let hour in hours) {
                    tmp_hour['data'].push({x: hours[hour], y: tmp_hours[hours[hour]]});
                }
                chart_hours.push(tmp_hour)
            }
            this.setState({loaded: true, footfall_years: chart_years, footfall_months: chart_months,
                footfall_days: chart_days, footfall_weekdays: chart_weekdays, footfall_hours: chart_hours},()=>{
                this.setState({widthAvailable: this.chartsRef.current.offsetWidth});
            })
        })
    }

    updateDimensions = () => {
        this.setState({widthAvailable: this.chartsRef.current.offsetWidth})
    }

    render() {
        if (this.state.loaded === false) {
            return <Loader/>
        }
        let year_chart = <div className={(this.state.collapseYear?'chart-item-act ':'') + 'chart-item years'}>
            <div onClick={()=>{this.setState({collapseYear: !this.state.collapseYear})}} className={'chart-header'}> <h2>{strings.data_years}</h2><i className="ico-arrow"/></div>
            <Collapse isOpened={this.state.collapseYear}><div className={'chart-body'}>{this.state.collapseYear?<FootfallDetailChart width={this.state.widthAvailable} bottomLabel={strings.data_years} data={this.state.footfall_years} />:null}</div></Collapse>
        </div>;

        let month_chart = <div className={(this.state.collapseMonth?'chart-item-act ':'') + 'chart-item months'}>
            <div onClick={()=>{this.setState({collapseMonth: !this.state.collapseMonth})}} className={'chart-header'}> <h2>{strings.data_months}</h2><i className="ico-arrow"/></div>
            <Collapse isOpened={this.state.collapseMonth}><div className={'chart-body'}>{this.state.collapseMonth?<FootfallDetailChart width={this.state.widthAvailable} bottomLabel={strings.data_months} data={this.state.footfall_months} />:null}</div> </Collapse>
        </div>

        let day_chart = <div className={(this.state.collapseDay?'chart-item-act ':'') + 'chart-item days'}>
            <div onClick={()=>{this.setState({collapseDay: !this.state.collapseDay})}} className={'chart-header'}><h2>{strings.data_days}</h2><i className="ico-arrow"/></div>
            <Collapse isOpened={this.state.collapseDay}><div className={'chart-body'}>{this.state.collapseDay?<FootfallDetailChart width={this.state.widthAvailable} bottomLabel={strings.data_days}  data={this.state.footfall_days} />:null}</div></Collapse>
        </div>

        let weekday_chart = <div className={(this.state.collapseWeekday?'chart-item-act ':'') + 'chart-item weekday'}>
            <div onClick={()=>{this.setState({collapseWeekday: !this.state.collapseWeekday})}} className={'chart-header'}><h2>{strings.data_weekday}</h2><i className="ico-arrow"/></div>
            <Collapse isOpened={this.state.collapseWeekday}><div className={'chart-body'}>{this.state.collapseWeekday?<FootfallPieChart width={this.state.widthAvailable}bottomLabel={strings.data_weekday} data={this.state.footfall_weekdays} />:null}</div></Collapse>
        </div>

        let hour_chart = <div className={(this.state.collapseHour?'chart-item-act ':'') + 'chart-item hours'}>
            <div onClick={()=>{this.setState({collapseHour: !this.state.collapseHour})}} className={'chart-header'}><h2>{strings.data_hours}</h2><i className="ico-arrow"/></div>
            <Collapse isOpened={this.state.collapseHour}><div className={'chart-body'}>{this.state.collapseHour?<FootfallDetailChart width={this.state.widthAvailable} bottomLabel={strings.data_hours}  data={this.state.footfall_hours} />:null}</div></Collapse>
        </div>

        return <div className={'footfall-data-detail'} ref={this.chartsRef}>
            <div className={'charts'}>
                {year_chart}
                {month_chart}
                {day_chart}
                {weekday_chart}
                {hour_chart}
            </div>
        </div>
    }
}


class FootfallDetailChart extends Component {

    graphOffset = 220;
    legendRotationMax = 100;
    legendWidthMin = 12;
    minValueWidth = 7;

    render() {
        let columns = [{id: 'season', accessor: 'season', Header: strings.season, disableFilters: true}]

        let count = 0;
        let tmp_data = {}
        let table_data = []
        let tick_values = [];

        console.log(this.props.data);

        for (let row in this.props.data) {
            count = this.props.data[row].data.length;
            columns.push({
                id: 'rid_'+row,
                accessor: 'rid_'+row,
                Header: this.props.data[row].id,
                disableFilters: true
            })
            for (let index in this.props.data[row].data) {
                let row2 = this.props.data[row].data[index];
                if (tmp_data[row2['x']] === undefined) tmp_data[row2['x']] = {};
                tmp_data[row2['x']]['rid_'+row] = row2['y'];
            }
        }

        let counter_tick = 0;

        for (let season in tmp_data) {
            let row = tmp_data[season];
            row.season = season;
            table_data.push(row);
        }

        console.log(table_data);

        let currentSize = (this.props.width - this.graphOffset)/(count-1);
        let graphWidth = Math.max(this.props.width, ((count*this.minValueWidth) + this.graphOffset),500);
        let pointSize = Math.min(Math.floor(currentSize), 10);
        let truePointWidth = (graphWidth-this.graphOffset)/(count-1);
        let bottomRotation = (truePointWidth<this.legendRotationMax) ? 90:0;
        let tick_index = (truePointWidth<this.legendWidthMin)?2:1;
        for (let tick in this.props.data[0].data) {
            if ( !(counter_tick%tick_index)) tick_values.push(this.props.data[0].data[tick].x)
            counter_tick++;
        }

        return <div className={'analytics-data'}>{count>=2?<div className={'chart-overall'}><div className={'chart'}><ResponsiveLine
            data={this.props.data}
            margin={{top: 50, right: 180, bottom: bottomRotation?120:45, left: 65}}
            xScale={{type: 'point'}}
            yScale={{type: 'linear', min: 'auto', max: 'auto', reverse: false}}
            axisTop={null}
            axisRight={null}
            width={graphWidth}
            axisBottom={{
                orient: 'bottom',
                // tickSize: (value)=>{console.log(value); return 0},
                format: (value) => {return (tick_values.indexOf(value)!==-1) ? value : ''},
                tickPadding: 25,
                tickRotation: bottomRotation,
                legend: this.props.bottomLabel,
                legendOffset: 20,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Footfall',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            pointSize={pointSize}
            pointColor={{theme: 'background'}}
            pointBorderWidth={2}
            pointBorderColor={{from: 'serieColor'}}
            pointLabelYOffset={-12}
            useMesh={true}
            theme={theme}
            colors={{scheme: 'category10'}}
            tooltipLabel={(e) => {
                return e.indexValue
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
        </div></div>:null}
            <TorlinTable className={'table'} data={table_data} columns={columns} />
        </div>
    }
}


class FootfallPieChart extends Component {
    render() {
        return <div className={'analytics-data'}><div className={'chart-overall'}><div className={'chart'}>
            <ResponsiveSunburst
                data={this.props.data}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                id="name"
                value="footfall"
                width={this.props.width}
                childColor={{ from: 'color', modifiers: [ [ 'brighter', 0.4 ] ] }}
                borderColor={{ theme: 'background' }}
                theme={theme}
                colors={{scheme: 'category10'}}
                enableArcLabels={true}
                arcLabelsSkipAngle={9}
                arcLabel={function(e) {
                    if (e.depth === 1) {
                        if (e.height>0) {
                            return e.id + " (" +e.formattedValue+")"
                        } else {
                            return e.id + " (" +e.formattedValue+")"
                        }
                    } else if (e.depth === 2) {
                        let id = (e.id.indexOf(' - ')!==-1)?e.id.substr(0, e.id.indexOf(' - ')):e.id;
                        return id + " ("+e.value+")";
                    }
                    return e.value;
                }}
            />
        </div></div></div>
    }
}
