import {BrowserRouter, Switch, Route} from "react-router-dom"
import React,{Component} from "react";
import Login from "./modules/Login/Login";
import Api from "./libraries/Api/Api";
import Cameras from "./modules/Cameras/Cameras";
import Analytics from "./modules/Analytics/Analytics";
import MobileApp from "./modules/MobileApp/MobileApp";
import Header from "./libraries/Header/Header";
import CamDetail from "./modules/CamDetail/CamDetail";
import Footer from "./libraries/Footer/Footer";
import Settings from "./modules/Settings/Settings";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TorlinModal from "./libraries/TorlinModal/TorlinModal";
import Stream from "./modules/Stream/Stream";
import MailsPublic from "./modules/Mails/MailsPublic";
import {createTheme, ThemeProvider} from "@mui/material";
import {csCZ} from "@mui/x-data-grid";
import {csCZ as coreCsCz} from "@mui/material/locale"
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('8660d9c9fbf5a820484b6e3dfed1ef3dTz03ODE0MCxFPTE3MzA4OTk2MDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openLogout: false,
        }
        this.toggleLogoutView = this.toggleLogoutView.bind(this);
        this.router = React.createRef();
        this.api = new Api()
        this.header = React.createRef();
    }

    toggleLogoutView() {
        this.setState({openLogout: !this.state.openLogout})
    }

    render() {
        const darkTheme = createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#2E83F4',
                    dark: '#215fb0',
                    contrastText: '#fff'
                },
                background: {
                    default: '#0F1114',
                    paper: '#171d21'
                },
                divider: '#7b87a4'
            },
            typography: {
                fontSize: 20
            },
        },csCZ,coreCsCz);

        if (this.api.user.logged) {
            return (
                <div>
                    <ThemeProvider theme={darkTheme}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className={'page' + (this.state.openLogout?' page-loggout':'')}>
                        <BrowserRouter ref={this.router}>
                            <Header ref={this.header} router={this.router} onLogout={this.toggleLogoutView} api={this.api}/>
                            <section>
                                <div className="in">
                                    <Switch>
                                        <Route path={'/stream:key'} name={'Stream'} render={()=><Stream api={this.api} />} />
                                        <Route path={'/settings'} name={'Settings'}
                                               render={() => <Settings api={this.api}/>}/>
                                        <Route path={'/mails'} name={'Settings'}
                                               render={() => <MailsPublic
                                                   header={this.header}
                                                   api={this.api}/>}/>
                                        <Route path={'/analytics'} name={'Analytics'}
                                               render={() => <Analytics api={this.api}/>}/>
                                        <Route path={'/torlin_alarm'} name={'TORLIN Alarm'}
                                               render={() => <MobileApp api={this.api}/>}/>
                                        <Route path={'/cams/:id'} name={'TORLIN Alarm'}
                                               render={(props) => <CamDetail {...props} api={this.api} />}/>
                                        <Route path={'*'} name={'Cameras'} render={() => <Cameras api={this.api}/>}/>
                                    </Switch>
                                </div>
                            </section>
                        </BrowserRouter>
                        <Footer api={this.api}/>
                    </div>
                    <TorlinModal
                        open={this.state.openLogout}
                        cancelCallback={()=>{this.setState({openLogout: false})}}
                        text={'Opravdu se chcete odhlásit?'}
                        confirmText={'Odhlásit'}
                        callback={()=>this.api.logout()}/>
                    </ThemeProvider>
                </div>
            )
        } else {
            return <BrowserRouter>
                <Switch>
                    <Route path={'/stream/:key'} name={'Stream'} render={(props)=><Stream {...props} api={this.api} />} />
                    <Route path={'*'} name={'Cameras'} render={() => <Login api={this.api} />}/>
                </Switch>
            </BrowserRouter>
        }
    }
}
export default App;
