import {
    AppBar,
    Box, Button, Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import * as React from 'react';
import { ResponsivePie } from '@nivo/pie'
import {theme} from "../Analytics/FootfallDataDetail";
import {InfoOutlined} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {setDetailView} from "../../ReduxStores/Dolak";

export default function DolakTables(props) {
    const {data, showNobody, cars} = props;

    return <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
            m: 1,
            width: 450,
        },
    }}>
        {Object.keys(data.label).map(table=>{
            return <Box key={'table_'+table}>
                <DolakTable
                    showNobody={showNobody}
                    cars={cars}
                    label={data.label[table]}
                    ratio={data.ratio[table]}
                    counts={data.counts[table]}
                    tableId={table}
                />
            </Box>
        })}
    </Box>
}


function DolakTable(props) {
    const {label, ratio, showNobody, counts, tableId, cars} = props;
    const dispatch = useDispatch();
    let total = (cars) ? ratio[4] + ratio[5] : ratio[1]+ratio[2]+ratio[3];

    const rows = [];

    if (cars) {
        const car_average = ratio[4]/counts[4];
        const nocar_average = ratio[5]/counts[5];

        rows.push({name: 'Výdeje auta', value: Math.floor(car_average/60)+'min '+Math.round(car_average%60)+'s', total: counts[4]});
        rows.push({name: 'Prádzného místa', value: Math.floor(nocar_average/60)+'min '+Math.round(nocar_average%60)+'s', total: counts[5]})
    } else {
        const dealer_average= ratio[1]/counts[1];
        const customer_average= ratio[2]/counts[2];
        const both_average= ratio[3]/counts[3];

        rows.push({name: 'Obchodníka', value: Math.floor(dealer_average/60)+'min '+Math.round(dealer_average%60)+'s', total: counts[1]});
        rows.push({name: 'Zákazníka', value: Math.floor(customer_average/60)+'min '+Math.round(dealer_average%60)+'s', total: counts[2]});
        rows.push({name: 'Oba', value: Math.floor(both_average/60)+'min '+Math.round(both_average%60)+'s', total: counts[3]});
    }


    const data= (cars) ? [{
        'id': 'car',
        'label': 'Výdej auta',
        'value': ratio[4],

    }, {
        'id': 'nocar',
        'label': 'Prázdné místo',
        'value': ratio[5],
    }] : [{
        'id': 'dealer',
        'label': 'Obchodník',
        'value': ratio[1],

    }, {
        'id': 'both',
        'label': 'Oba',
        'value': ratio[3],
    }, {
        'id': 'customer',
        'label': 'Zákazník',
        'value': ratio[2],

    }];

    if (showNobody) {
        data.push({
            'id': 'nobody',
            'label': 'Prázdný',
            'value': ratio[0],
        })
        total += ratio[0];

        const empty_average= ratio[0]/counts[0];
        rows.push(
            {name: 'Prázdný stůl', value: Math.floor(empty_average/60)+'min '+Math.round(empty_average%60)+'s'}
        )
    }

    return <Paper>
        <Toolbar>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Typography variant={'h6'}>{label}</Typography>
                </Grid>
                <Grid item>
                    <Button size="small" endIcon={<InfoOutlined />} onClick={_=>dispatch(setDetailView({section: 'table', item: tableId}))}>
                        Podrobnosti
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
        <Box sx={{width: 450, height: 350, mt: 3}}><ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            theme={theme}
            colors={{scheme: 'category10'}}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLabel={d=>Math.round((d.value/total)*10000)/100 + '%'}
            arcLinkLabel={d=>d.label}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#CCC"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            tooltipColor={'#ccc'}
            tooltip={({
                          datum: {
                              id,
                              label,
                              value,
                              color
                          }
                      }) => <div style={{
                padding: 12,
                color,
                borderRadius: 4,
                background: '#222222'
            }}>
                <strong>
                    {label}: {Math.floor(value/3600)} hodin {Math.round((value-Math.floor(value/3600)*3600)/60)} minut
                </strong>
            </div>}
            valueFormat={d=> {
                return Math.round((d/total)*10000)/100 + '%'
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'nobody'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'dealer'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'nocar'
                    },
                    id: 'lines'
                },
            ]}
            /></Box>
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="Data-table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Název</TableCell>
                            <TableCell align="right">Průměrná doba</TableCell>
                            <TableCell align="right">Celkem</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Paper>
}