import React,{Component} from "react";
import * as PropTypes from "prop-types";
import LocalizedStrings from "react-localization";
import Loader from "../../libraries/Loader/Loader";
import {createReactEditorJS} from "react-editor-js";
import Header from '@editorjs/header'
import Image from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import Raw from '@editorjs/raw'
import Quote from '@editorjs/quote'
import TorlinTable from "../../libraries/TorlinTable/TorlinTable";
import Popup from "reactjs-popup";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

let strings = new LocalizedStrings({
    en: {
        newMail: 'New Mail',
        newMailTitle: 'Name of Mail',
        save: 'Save',
        title: 'Title',
        cancel: 'Cancel',
        options: 'Options',
        cancelPopupTitle: 'Are you sure you want to cancel editing?',
        cancelPopupText: 'All edits will be deleted.',
        no: 'No',
        published: 'Publish Date',
        approved: 'Approved',
    },
    cs: {
        newMail: 'Nová zpráva',
        newMailTitle: 'Název zprávy',
        save: 'Uložit',
        title: 'Nadpis',
        cancel: 'Zrušit',
        options: 'Možnosti',
        cancelPopupTitle: 'Opravdu chcete zrušit úpravy?',
        cancelPopupText: 'Všechny úravy budou smazány.',
        no: 'Ne',
        published: 'Datum vydání',
        approved: 'Schváleno'
    }
})

class NewMailForm extends Component {
    constructor(props) {
        super(props);
        this.title = React.createRef();
        this.published = React.createRef();
        const date = new Date();

        this.state = {
            title: '',
            blocks: [],
            published: date.getTTime,
            approved: false
        }
    }

    componentDidMount() {
        if (this.props.editRow) {
            this.props.api.getMail(this.props.editRow).then(res=>{
                let published = new Date(res.published);
                this.setState({
                    title: res.title,
                    blocks: res.mail,
                    published: published.getTTime,
                    approved: res.approved===1
                })
            });
        }
    }

    render() {
        if (this.props.editRow && this.state.blocks.length === 0) return <Loader />
        const ReactEditorJS = createReactEditorJS()
        return <div className={'new-mail-form'}>
            <div className={'title'}>
                <label htmlFor={'title'}>{strings.newMailTitle}:</label>
                <input ref={this.title} type={'text'} defaultValue={this.state.title} />
            </div>
            <div className={'published'}>
                <label htmlFor={'published'}>{strings.published}:</label>
                <input ref={this.published} type={'datetime-local'} defaultValue={this.state.published} />
            </div>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    onChange={(event,value)=>{this.setState({approved: value})}}
                    checked={this.state.approved} /> } label={strings.approved} />
            </FormGroup>
            <ReactEditorJS
                tools={{
                header: Header,
                image: {
                    class: Image,
                    config: {
                        uploader: {
                            uploadByFile: (image) => {
                                return this.props.api.uploadMailImage(image).then(res => {
                                    return res
                                })
                            }
                        }
                    }
                },
                paragraph: Paragraph,
                raw: Raw,
                quote: Quote
            }}
                onInitialize={(editor)=>{
                    this.editor = editor;
                }}
                defaultValue={this.state.blocks}
            />
            <div className={'edit-mail-buttons'}>
                <button className={'btn btn-new'} onClick={() => {
                    this.editor.save().then(data=>{
                        if (this.props.editRow) {
                            this.props.api.editMail(
                                this.props.editRow, data, this.title.current.value,
                                this.state.approved, this.published.current.value).then(()=>{
                                this.props.defaultViewCallback();
                            })
                        } else {
                            this.props.api.saveMail(data, this.title.current.value,
                                this.state.approved, this.published.current.value).then(()=>{
                                this.props.defaultViewCallback();
                            })
                        }
                    })
                }}>{strings.save}</button>
                <Popup api={this.props.api} trigger={<button className={'btn-cancel'}>{strings.cancel}</button>} modal>
                    {close => (
                        <div className="modal">
                            <button className="close" onClick={close}>
                                &times;
                            </button>
                            <div className="header"> {strings.cancelPopupTitle} </div>
                            <div className="content">
                                {strings.cancelPopupText}
                            </div>
                            <div className="actions">
                                <button className={'btn-danger'} onClick={()=>{this.props.defaultViewCallback()}}>
                                    {strings.cancel}
                                </button>
                                <button className={'btn-cancel'} onClick={() => {close();}}>{strings.no}</button>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>
        </div>;
    }
}

class Mails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'loading',
            columns: [
                {
                    Header: 'ID',
                    accessor: 'id',
                },
                {
                    Header: strings.title,
                    accessor: 'title',
                },
                {
                    Header: strings.published,
                    accessor: 'published',
                    Cell: ({value}) => {
                        let date = new Date(value);
                        return <>{date.toLocaleString()}</>
                    }
                },
                {
                    Header: strings.approved,
                    accessor: 'approved',
                    Cell: ({value}) => {

                        return <Checkbox defaultChecked={value} disabled={true}/>
                    }
                },
                {
                    Header: strings.options,
                    id: 'options',
                    accessor: 'id',
                    Cell: ({value}) => (<>
                            <button className={'btn-edit'} onClick={()=>{
                                this.setState({
                                    editRowId: value,
                                    view: 'form'
                                })
                            }}>Edit</button>
                        </>
                    ),
                    disableSortBy: true,
                    Filter: false,
                }
                ],
            data: [],
            editRowId: null
        }
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        this.props.api.getMails().then(res => {
            if (res.status === 'ok') {
                this.setState({view: 'table', data: res.mails})
            }
        })
    }

    render() {
        if (this.state.view === 'table') {
            return <div className={'mails-header'}>
                <button className={'btn btn-new'} onClick={() => {
                    this.setState({view: 'form'})
                }}>{strings.newMail}</button>
                <TorlinTable columns={this.state.columns} data={this.state.data}/>
            </div>;
        } else if (this.state.view === 'loading') {
            return <Loader/>
        } else if (this.state.view === 'form') {
            return <NewMailForm {...this.props} editRow={this.state.editRowId} defaultViewCallback={() => {
                this.setState({view: 'loading'},()=>{this.updateData()})
            }}/>
        }
    }
}

Mails.propTypes = {
    children: PropTypes.node,
    api: PropTypes.object
};

export default Mails;
