import * as React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions, DialogContent, DialogTitle,
    Divider,
    Grid,
    Skeleton,
    Toolbar,
    Typography
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {setDetailView, setNeedUpdate} from "../../ReduxStores/Dolak";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro as DataGrid, GridActionsCellItem} from '@mui/x-data-grid-pro';
import dayjs from "dayjs";
import cs from 'dayjs/locale/cs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import {useEffect} from "react";
import {PlayCircleFilled} from "@mui/icons-material";
import Loader from "../../libraries/Loader/Loader";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoJS from "../../libraries/VideoJs/Videojs";

dayjs.extend(LocalizedFormat)
dayjs().locale(cs).format()

let updateData

export default function DolakTableDetail(props) {
    const {api, label} = props;
    const [loaded, setLoaded] = React.useState(null);
    const season = useSelector(state=>state.dolak.season)
    const days = useSelector(state=>state.dolak.days)
    const hours = useSelector(state=>state.dolak.hours)
    const needUpdate = useSelector(state=>state.dolak.needUpdate)
    const detailView = useSelector(state=>state.dolak.detailView)
    const [playRecordId, setPlayRecordId] = React.useState(null);
    const [playRecordSrc, setPlayRecordSrc] = React.useState(null);
    const [recordFrom, setRecordFrom] = React.useState(null);
    const [recordTill, setRecordTill] = React.useState(null);
    const [recordState, setRecordState] = React.useState(null);
    const [data, setData] = React.useState(null);
    const dispatch = useDispatch();

    if (loaded === null) {
        setLoaded(false);
    }

    function callApiUpdateDataTimeout(test) {
        if (needUpdate || loaded === false) {
            dispatch(setNeedUpdate(false));
            clearTimeout(updateData)
            updateData = setTimeout(callApiUpdate, 500);
        }
    }

    function callApiUpdate() {
        api.getDolakTableDetail(season, days, hours, detailView.item).then(res=>{
            setData(res.data);
            setLoaded(true);
        })
    }

    useEffect(callApiUpdateDataTimeout);

    const state_labels = ['Nikdo', 'Obchodník', 'Zákazník', 'Oba','Výdej', 'Prázdno'];

    const columns = [{ field: 'id', headerName: 'ID', width: 100},
    {
        field: 'state',
            headerName: 'Stav',
        width: 150,
        editable: false,
        valueFormatter: (params) => state_labels[params.value]
    },
    {
        field: 'from',
        headerName: 'Začátek',
        type: 'datetime',
        width: 190,
        editable: false,
        valueFormatter: (params)=>dayjs(params.value).format('llll')
    },
    {
        field: 'till',
            headerName: 'Konec',
        type: 'datetime',
        width: 190,
        editable: false,
        valueFormatter: (params)=>dayjs(params.value).format('llll')
    }, {
        field: 'duration',
            headerName: 'Strávená doba',
            type: 'number',
            width: 100,
            editable:  false,
            valueFormatter: (params)=>Math.floor(Number(params.value)/60)+' min, '+Math.round(Number(params.value)%60)+' s'
        },
        {
            field: 'action',
            headerName: 'Záznam',
            type: 'actions',
            width: 100,
            editable: false,
            getActions: ({id, row}) => {
                return [<GridActionsCellItem
                    icon={<PlayCircleFilled />}
                    label="Spustit video"
                    className="textPrimary"
                    onClick={()=>{
                        setRecordFrom(row.from);
                        setRecordTill(row.till);
                        setRecordState(row.state);
                        setPlayRecordId(id);
                        api.getDolakRecordSrc(id).then(res=>{
                            setPlayRecordSrc(res.playlist.url)
                        })
                    }}
                    color="inherit"
                />,]
            }
        }
    ]

    function closePlayer() {
        if (playerRef.current) playerRef.current.dispose()
    }

    const playerRef = React.useRef(null);
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        // player.fill(true);
        player.play();

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            setPlayRecordSrc(null);
            setPlayRecordId(null);
        });
    };

    return <Box>
        <Toolbar>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Typography variant={'h6'}>Podrobnosti stolu: {label}</Typography>
                </Grid>
                <Grid item>
                    <Button size="small" startIcon={<ArrowBackIcon />} onClick={_=>dispatch(setDetailView(false))}>
                        zpět
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
        <Divider />
        {loaded?<div>
            <DataGrid
                rows={data}
                columns={columns}
                checkboxSelection
                disableRowSelectionOnClick
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                }
                csvOptions={{
                    fileName: 'dolak-data',
                    delimiter: ';',
                    utf8WithBom: true,
                }}
                autoHeight={true}
                pagination
            />
            <Dialog open={playRecordId!==null} onClose={closePlayer} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle id="alert-dialog-title">
                    {"Zobrazení záznamu (" + state_labels[recordState] + ") " + dayjs(recordFrom).format('LL LTS') + " - " + dayjs(recordTill).format("LTS")}
                </DialogTitle>
                    {playRecordSrc?<VideoJS options={{
                        sound: false,
                        controls: true,
                        muted: true,
                        autoplay: true,
                        width: 1200,
                        height: 768,
                        html5: {
                            nativeAudioTrack: false,
                            nativeVideoTracks: false
                        },
                        controlBar: {
                            muteToggle: false,
                            volumePanel: false,
                            fullscreenToggle: true,
                        },
                        sources: [{
                            src: playRecordSrc,
                            type: 'application/x-mpegURL',
                            withCredentials: true

                        }]}} onReady={handlePlayerReady} />:<DialogContent sx={{width: "100%", height: 500}}><Box sx={{pt: 27}} ><Loader /></Box></DialogContent>}
                <DialogActions>
                    <Button onClick={closePlayer}>Zavřít</Button>
                </DialogActions>
            </Dialog>
        </div>:<Box>
            <Skeleton height={80}/>
            <Skeleton height={80}/>
            <Skeleton height={80}/>
            <Skeleton height={80}/>
            <Skeleton height={80}/>
        </Box>
        }
    </Box>
}