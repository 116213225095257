import {Component} from "react";
import LocalizedStrings from "react-localization";
import Select from "react-select";
import {reactSelectDarkTheme} from "../../libraries/ReactSelect/DarkTheme";
import {toast} from "react-toastify";

let strings = new LocalizedStrings({
    en: {
        name: 'Name',
        parents: 'Parents',
        cancel: 'Cancel',
        save: 'Save'
    },
    cs: {
        name: 'Název role',
        parents: 'Rodiče',
        cancel: 'Zrušit',
        save: 'Uložit'
    }
})

export default class RoleForm extends Component {
    constructor(props) {
        super(props);

        let roles = this.props.roles;
        let form_data = [];
        for (let role in roles) {
            form_data.push({value: roles[role].id, label: roles[role].name})
        }

        let parents = [];
        if (this.props.role) {
            for (let role in this.props.role.parents) {
                parents.push({value: this.props.role.parents[role].id, label: this.props.role.parents[role].name})
            }
        }
        this.state = {
            name: this.props.role?this.props.role.name:'',
            form_data: form_data,
            parents: parents
        }
    }

    render() {
        return <div className="settings">
            <div className="settings-form">
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.name}:</span>
                        <input type="text" value={this.state.name} onChange={(elm)=>{this.setState({name: elm.target.value})}}/>
                    </label>
                </div>
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.parents}</span>
                        <Select styles={reactSelectDarkTheme}
                                value={this.state.parents}
                                className={'react-select'}
                                options={this.state.form_data}
                                isMulti={true}
                                onChange={elm=>{this.setState({parents: elm})}}/>
                    </label>
                </div>
                <div className="form-footer">
                    <button className="btn-gray" onClick={this.props.cancelCallback}>{strings.cancel}</button>
                    <button type="submit" className="btn-save" onClick={()=>{
                        let data = {
                            name: this.state.name,
                            parents: this.state.parents
                        }
                        if (!this.props.new) {
                            data['role_id'] = this.props.role.id;
                        }
                        this.props.api.addOrUpdateRole(data).then(res=>{
                            if (res.status === 'ok') {
                                this.props.updateCallback()
                            } else {
                                toast.error(res.message)
                            }
                        });
                    }}>{strings.save}</button>
                </div>
            </div>
        </div>
    }
}
