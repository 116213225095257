import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

function TorlinDateRangePicker({onChanged, startDate, endDate, clearIcon, dateMax, dateMin}) {
    const [value, onChange] = useState([startDate, endDate]);

    return (
        <div className={'torlin-datepicker'}>
            <DateRangePicker
                onChange={(value)=>{
                    if(onChanged) {
                        onChanged({startDate: value[0], endDate: value[1]});
                    }
                    onChange(value)}
                }
                clearIcon={clearIcon?'<ClearIcon />':null}
                value={value}
                minDate={dateMin}
                maxDate={dateMax}
            />
        </div>
    );
}


export default TorlinDateRangePicker;
