import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import LocalizedStrings from "react-localization";


let strings = new LocalizedStrings({
    en: {
        open: 'Open',
        close: 'Close',
        alertRuleAll: 'All',
        alertRuleLimited: 'Limited'
    },
    cs: {
        open: 'Rozbalit',
        close: 'Sbalit',
        alertRuleAll: 'Vše',
        alertRuleLimited: 'Omezené'
    }
})

export default class AlertSelectList extends Component {
    state = {
        loaded: false,
        alerts: {},
        allowed: {},
    }
    newAllowed = {}

    getCheckedTree() {
        return this.state.checkedTree;
    }

    componentDidMount() {
        this.props.api.loadAlerts(this.props.currentUser).then(res => {
            this.newAllowed = (Array.isArray(res.allowed)) ? {} : res.allowed
            this.setState({
                loaded: true,
                alerts: res.alerts,
                allowed: this.newAllowed
            })
        });
    }

    getValues() {
        return this.newAllowed;
    }

    render() {
        if (this.state.loaded === false) return <Loader/>
        return <div className="alert-rule-list">
            <h3>Alerty:</h3>
            {Object.keys(this.state.alerts).map(alert => {
                let allowed = this.newAllowed;
                return <AlertRuleSelect
                    key={'alert_id_' + alert}
                    alert={this.state.alerts[alert]}
                    id={alert}
                    allChecked={allowed[alert]&&allowed[alert]==='all'}
                    limitChecked={allowed[alert]&&allowed[alert]==='limited'}
                    newValue={(value) => {
                        if (value.all === true) {
                            allowed[alert] = 'all';
                        } else if(value.limited === true) {
                            allowed[alert] = 'limited'
                        } else {
                            if (allowed[alert]) {
                                delete allowed[alert];
                            }
                        }
                        console.log(allowed);
                        this.newAllowed = allowed
                    }}
                />
            })}
            })
            <div className={'clear'}/>
        </div>
    }
}


class AlertRuleSelect extends Component {
    state = {
        all: this.props.allChecked,
        limited: this.props.limitChecked
    }

    render() {
        return <div className={"alert-item-checkbox"}>
            <span className={"alert-name"}>{this.props.alert}</span>
            <label htmlFor={"alert_full_access_" + this.props.id} className={"form-item-checkbox"} onClick={() => {
                let value = {all: !this.state.all, limited: false}
                this.setState(value, this.props.newValue(value))
            }}>
                <input id={"alert_full_access" + this.props.id} type={'checkbox'} value={""} checked={this.state.all} readOnly/>
                <span className="like-checkbox"/>
                <span className={"alert-check-text"}>{strings.alertRuleAll}</span>
            </label>
            <label htmlFor={"alert_limit_access_" + this.props.id} className={"form-item-checkbox"} onClick={() => {
                let value = {all: false, limited: !this.state.limited}
                this.setState(value,this.props.newValue(value))
            }}>
                <input id={"alert_limit_access" + this.props.id} type={'checkbox'} value={""} checked={this.state.limited} readOnly/>
                <span className="like-checkbox"/>
                <span className={"alert-check-text"}>{strings.alertRuleLimited}</span>
            </label>
        </div>
    }
}
