import {Component} from "react";
import CamerasRow from "./CamerasRow";
import LocalizedStrings from "react-localization";
import Loader from "../../libraries/Loader/Loader";

let strings = new LocalizedStrings({
    en: {
        favorite: 'Favorites'
    },
    cs: {
        favorite: 'Oblíbené'
    }
});

export default class Cameras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            favorites: []
        }

        this.updateFavorites = this.updateFavorites.bind(this);
    }

    componentDidMount() {
        this.props.api.loadCameras().then(res => {
            if (res) {
                this.setState({data: res.cids, favorites: res.favorites_cams})
            } else {
                //todo: what to do when no data received
            }
        })
    }

    updateFavorites(item, mode) {
        let favorites = this.state.favorites;
        if (mode) {
            item.favorite = mode;
            favorites.push(item);
            this.props.api.addFavCam(item.id);
        } else {
            let new_favorites = [];
            for (let index in favorites) {
                if (!favorites.hasOwnProperty(index)) continue;
                if (favorites[index].id !== item.id) {
                    new_favorites.push(favorites[index])
                }
            }
            favorites = new_favorites;
            this.props.api.removeFavCam(item.id);
        }
        this.setState({favorites: favorites})
    }

    render() {
        if (!this.state.data || this.state.data.length === 0) {
            //todo: loading bar?
            return <Loader/>
        } else {
            let favorites_html = null;
            if (this.state.favorites.length) {
                let item = {
                    id: 0,
                    label: strings.favorite,
                    cameras: this.state.favorites
                }
                favorites_html = <CamerasRow key={'neuron_favorite'} data={item} api={this.props.api} favCallback={this.updateFavorites}/>
            }
            return (
                <div className="dashboard">

                    {favorites_html}
                    {this.state.data.map(neuron => {
                        let cameras = []
                        for (let room in neuron.rooms) {
                            if (!neuron.rooms.hasOwnProperty(room)) continue;
                            let room_object = neuron.rooms[room].cameras;
                            for (let camera in room_object) {
                                if (!room_object.hasOwnProperty(camera)) continue;
                                if (room_object[camera].favorite) {

                                }
                                cameras.push({
                                    id: room_object[camera].id,
                                    label: room_object[camera].label,
                                    last: room_object[camera].last,
                                    image: room_object[camera].image,
                                    favorite: room_object[camera].favorite
                                })
                            }
                        }
                        let item = {
                            id: neuron.id,
                            label: neuron.label,
                            cameras: cameras
                        }

                        return (<CamerasRow key={'neuron_' + neuron.id} data={item} api={this.props.api} favCallback={this.updateFavorites}/>)
                    })}
                </div>
            )
        }
    }
}
