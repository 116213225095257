export default class User {
    username = '';
    password = '';
    language = '';
    firstname = '';
    surname = '';
    email = '';
    phone = '';
    logged = false;
    rules = {};
    roles = {};

    save() {
        this.password = '****'
        sessionStorage.username = this.username;
        sessionStorage.language = this.language;
        sessionStorage.firstname = this.firstname;
        sessionStorage.surname = this.surname;
        sessionStorage.email = this.email;
        sessionStorage.phone = this.phone;
        sessionStorage.logged = this.logged;
        sessionStorage.roles = JSON.stringify(this.roles);
        sessionStorage.rules = JSON.stringify(this.rules);
    }

    load() {
        if (sessionStorage.username) {
            this.username = sessionStorage.username;
        }
        if (sessionStorage.language) {
            this.language = sessionStorage.language;
        }
        if (sessionStorage.logged) {
            this.logged = sessionStorage.logged;
        }
        if (sessionStorage.firstname) {
            this.firstname = sessionStorage.firstname;
        }
        if (sessionStorage.surname) {
            this.surname = sessionStorage.surname;
        }
        if (sessionStorage.phone) {
            this.phone = sessionStorage.phone;
        }
        if (sessionStorage.email) {
            this.email = sessionStorage.email;
        }
        if (sessionStorage.roles) {
            this.roles = JSON.parse(sessionStorage.roles);
        }
        if (sessionStorage.rules) {
            this.rules = JSON.parse(sessionStorage.rules);
        }
    }

    logout() {
        sessionStorage.clear();
        this.username = '';
        this.language = '';
        this.surename = '';
        this.firstname = '';
        this.email = '';
        this.phone = '';
        this.roles = {};
        this.rules = {};
        this.logged = false;
    }

    getLabel() {
        if (this.firstname || this.surname) {
            return this.firstname + " " + this.surname;
        } else {
            return this.username;
        }
    }

    isAllowed(resource, rule) {
        return this.rules[resource]?this.rules[resource][rule]!==undefined?this.rules[resource][rule]:false:false;
    }

}
