import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import FootfallData from "./FootfallData";
import FootfallDataDetail from "./FootfallDataDetail";
import LocalizedStrings from "react-localization";
import Select from "react-select";
import {reactSelectDarkTheme} from "../../libraries/ReactSelect/DarkTheme";
import TorlinDateRangePicker from "../../libraries/Datepicker/Datepicker";

let strings = new LocalizedStrings({
    en: {
        from: 'From',
        till: 'Till',
        activateFilter: 'Select to filter...',
        footfallDetail: 'Footfall detail',
        footfallOverall: 'Footfall overall'
    }, cs: {
        from: 'Od',
        till: 'Do',
        activateFilter: 'Filtrovat oblasti...',
        footfallDetail: 'Detailně',
        footfallOverall: 'Celkově'
    }
})

export default class Footfall extends Component {
    constructor(props) {
        super(props);
        let startDate = new Date();
        startDate.setMonth((startDate.getMonth() - 1));
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        this.state = {
            startDate: startDate,
            endDate: new Date(),
            focusedInput: null,
            footfallData: null,
            footfallDetail: null,
            rids_option: [],
            rids_filter: []
        }
        this.saveFilter = this.saveFilter.bind(this);
        this.loadFilter = this.loadFilter.bind(this);
        this.showFootfallDetail = this.showFootfallDetail.bind(this);
        this.showFootfallOverall = this.showFootfallOverall.bind(this);
    }

    componentDidMount() {
        this.loadFilter();
        this.updateDate();
    }

    updateDate() {
        this.saveFilter();
        if (!this.state.footfallDetail) {
            this.props.api.getFootfallAnalytics(this.state.startDate, this.state.endDate).then(res => {
                let rids = [];
                for (let rid in res.data) {
                    rids.push({value: rid, label: res.data[rid].name})
                }
                this.setState({footfallData: res.data, rids_option: rids});
            });
        } else {
            this.showFootfallDetail()
        }
    }


    showFootfallDetail() {
        let rids = [];
        for (let row in this.state.rids_filter) {
            rids.push(this.state.rids_filter[row].value);
        }
        this.setState({footfallDetail: rids})
    }


    showFootfallOverall() {
        this.setState({footfallDetail: null})
    }


    saveFilter() {
        localStorage.setItem('footfall_start_date', this.state.startDate);
        localStorage.setItem('footfall_end_date', this.state.endDate);
        localStorage.setItem('footfall_filter', JSON.stringify(this.state.rids_filter));
    }


    loadFilter() {
        let startDate = localStorage.getItem('footfall_start_date');
        startDate = startDate?new Date(startDate):this.state.startDate;
        let endDate = localStorage.getItem('footfall_end_date');
        endDate = endDate?new Date(endDate):this.state.endDate;
        let rids_filter;

        try{
            rids_filter = JSON.parse(localStorage.getItem('footfall_filter'));
        } catch (e) {
            rids_filter = {}
        }
        this.setState({
            startDate: startDate,
            endDate: endDate,
            rids_filter: rids_filter
        });
    }

    render() {
        let footfall;
        let action = null;
        if (this.state.footfallDetail) {
            footfall = <FootfallDataDetail backCallback={()=>{this.setState({footfallDetail: null})}} {...this.props} rids={this.state.footfallDetail}
                                           from={this.state.startDate} till={this.state.endDate} />
            action = <button onClick={this.showFootfallOverall}>{strings.footfallOverall}</button>
        } else if (this.state.footfallData) {
            footfall = <FootfallData
                callbackDetail={(rid)=>{this.setState({rids_filter: [rid]}, ()=>{this.saveFilter();this.showFootfallDetail();})}} {...this.props}
                data={this.state.footfallData}
                filter={this.state.rids_filter}
            />
            if (this.state.rids_filter && this.state.rids_filter.length) {
                action = <button onClick={this.showFootfallDetail}>{strings.footfallDetail}</button>
            }
        } else {
            footfall = <Loader />
        }

        return (
            <div className={'footfall'}>
                <div className={'footfall-header'}>
                    <div className={'datepicker-range'}>
                        <TorlinDateRangePicker
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChanged={(dates)=>{
                            this.setState({startDate: dates.startDate, endDate: dates.endDate}, ()=>{this.updateDate()})
                        }}/>
                    </div>
                    <div className={'filter'}>
                        <Select
                            placeholder={strings.activateFilter}
                            styles={reactSelectDarkTheme}
                            className={'react-select'}
                            isMulti={true}
                            value={this.state.rids_filter}
                            options={this.state.rids_option}
                            onChange={(value)=>{
                                if (this.state.footfallDetail && value.length === 0) {
                                    this.setState({rids_filter: [], footfallDetail: null}, ()=>this.updateDate());
                                } else {
                                    this.setState({rids_filter: value},()=>this.updateDate())}
                                }
                            }
                        />
                    </div>
                    <div className={'action'}>
                        {action}
                    </div>
                </div>
                <div className={'footfall-data'}>
                    {footfall}
                </div>
            </div>
        )
    }
}
