import * as PropTypes from "prop-types";
import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import {DataGrid} from "@mui/x-data-grid";
import {Box, Button, Chip, Divider, ScopedCssBaseline} from "@mui/material";
import LocalizedStrings from "react-localization";
import Blocks from 'editorjs-blocks-react-renderer'

const strings = new LocalizedStrings({
    en: {
        Title: 'Title',
        Annotation: 'Annotation',
        Published: 'Published',
        Read: 'Read',
        News: 'News',
        Back: 'Back',
        Message: 'Message'
    }, cs:{
        Title: 'Název',
        Annotation: 'Anotace',
        Published: 'Ze dne',
        Read: 'Přečteno',
        News: 'Nepřečteno',
        Back: 'Zpět',
        Message: 'Zpráva'
    }
})

class MailDetail extends Component {
    render() {
        return <div className={'mails'}>
                <Button onClick={this.props.callbackDefaultView} variant={"contained"}>{strings.Back}</Button>
                <Divider sx={{mb: 3,mt:1}}>{strings.Message}</Divider>
                <h1>{this.props.data.title}</h1>
                <Blocks data={this.props.data.mail} />
            </div>;
    }
}

MailDetail.propTypes = {children: PropTypes.node};

class MailsPublic extends Component {

    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);

        this.state = {
            status: 'loading',
            detail: {}
        }
    }

    componentDidMount() {
        this.updateData()
    }

    updateData() {
        this.props.api.getMails(true).then(res => {
            this.setState({status: 'loaded', data: res.mails, detail: {}})
        })
    }


    render() {
        if (this.state.status === 'loading') return <Loader/>
        else if (this.state.status === 'detail') {
            return <div><MailDetail
                callbackDefaultView={()=>{this.setState({view: 'loading'},
                    ()=>{this.updateData()})}}
                                    {...this.props} data={this.state.detail} /></div>
        }
        const columns = [{
            field: 'title',
            headerName: strings.Title,
            width: 150,
            editable: false,
        },{
            field: 'annotation',
            headerName: strings.Annotation,
            width: 350,
            editable: false,
        },{
            field: 'published',
            headerName: strings.Published,
            width: 100,
            editable: false,
            valueGetter: (value)=>{let date = new Date(value.value); return date.toLocaleDateString()}
        },{
            field: 'read',
            headerName: strings.Read,
            width: 100,
            editable: false,
            renderCell: (value)=>{return value.value?<Chip label={strings.Read}/>:<Chip color={'primary'} label={strings.News} />}
        }
        ]

        return (
            <ScopedCssBaseline>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <div style={{ height: 400, width: '100%'}}>
                        <DataGrid columns={columns} rows={this.state.data}
                                  getCellClassName={(params) =>{
                                      return 'row-pointer';
                                  }}
                                  pageSize={5} rowsPerPageOptions={[5]}
                                  onRowClick={(row)=>{
                                      this.props.api.getMail(row.id).then(res=>{
                                            this.setState({status: 'detail', detail: res});
                                            this.props.header.current.updateUnreadCount()
                                      })
                                  }}
                        />
                    </div>
                </Box>
            </ScopedCssBaseline>
        );
    }
}

MailsPublic.propTypes = {
    api: PropTypes.object
}

export default MailsPublic;
