import React,{Component} from 'react';
import Loader from "../../libraries/Loader/Loader";
import videojs from "video.js";

export default class Stream extends Component {
    state = {
        url: null,
        loaded: null,
        id: null
    }
    componentDidMount() {
        let object = this;
        let key = this.props.match.params.key;
        this.props.api.getKeyStream(key).then(res=>{
            if (res.url) {
                this.setState({
                    loaded: true, url: res.url, id: res.cameraId
                }, ()=>{
                    this.player = videojs(this.videoNode, {
                        sound: false,
                        controls: true,
                        muted: true,
                        autoplay: true,
                        fullscreen: true,
                        language: this.props.api.user.language,
                        controlBar: {
                            muteToggle: false,
                            volumePanel: false,
                            fullscreenToggle: true,
                        },
                        sources: [{
                            src: this.state.url,
                            type: 'application/x-mpegURL',
                            withCredentials: true
                        }],
                    }, function onPlayerReady() {
                        object.startKeepAlive();
                        this.play();
                    });
                })
            }
        })
    }


    componentWillUnmount() {
        this.stopKeepAlive();
    }


    startKeepAlive() {
        this.keepAlive = setInterval(()=>{
            this.props.api.keepAlive(this.state.id);
        }, 5000);
    }


    stopKeepAlive() {
        if (this.keepAlive) {
            clearInterval(this.keepAlive);
        }
    }


    render() {
        if (this.state.loaded) {
            return <div className={'camera-content-playing'} data-vjs-player={true}>
                <video ref={ node => this.videoNode = node } className="video-js" />
            </div>
        } else {
            return <Loader />
        }
    }
}
