import {Component} from "react";
import {toast} from "react-toastify";
import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
    en: {
        changePassword: 'Change Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        currentPassword: 'Current Password',
        errorShort: 'Password is too short. Minimum length is 6 chars.',
        errorNotMatched: 'New and Confirm password not matched.',
        changeButton: 'Change',
        passwordChanged: 'Password changed successfully.',
        'Current Password does not matched': "Current Password does not matched.",
    },
    cs: {
        changePassword: 'Změna hesla',
        newPassword: 'Nové heslo',
        confirmPassword: 'Potvrdit nové heslo',
        currentPassword: 'Současné heslo',
        errorShort: 'Heslo je moc krátké. Minimální délka je 6 znaků',
        errorNotMatched: 'Nové heslo se neshoduje s potvrzujícím heslem.',
        changeButton: 'Změnit',
        passwordChanged: 'Heslo bylo úspěšně změněno.',
        'Current Password does not matched': "Nezadali jste správně současné heslo.",
    }
})

export default class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        }
    }


    render() {
        return <div>
            <div className={'settings'}>
                <h1>{strings.changePassword}:</h1>
                <div className={'settings-form'}><form>
                    <div className={'form-item'}>
                        <label>
                            <span>{strings.currentPassword}:</span>
                            <input autoComplete={'false'} onChange={(val)=>{this.setState({currentPassword: val.target.value})}} name={'currentPassword'} type={'password'} value={this.state.currentPassword} />
                        </label>
                    </div>
                    <div className={'form-item'}>
                        <label htmlFor={'newPassword'}>
                            <span>{strings.newPassword}:</span>
                            <input autoComplete={'false'} onChange={(val)=>{this.setState({newPassword: val.target.value})}}  name={'newPassword'} id={'newPassword'} type={'password'} value={this.state.newPassword} />
                        </label>
                    </div>
                    <div className={'form-item'}>
                        <label htmlFor={'confirmPassword'}>
                            <span>{strings.confirmPassword}:</span>
                            <input autoComplete={'false'} onChange={(val)=>{this.setState({confirmPassword: val.target.value})}}  name={'confirmPassword'} type={'password'} value={this.state.confirmPassword} />
                        </label>
                    </div>
                </form></div>
                <div>
                    <button onClick={()=>{
                        if (this.state.newPassword.length<6) {
                            toast.error(strings.errorShort)
                        } else if (this.state.newPassword !== this.state.confirmPassword) {
                            toast.error(strings.errorNotMatched)
                        } else {
                            this.props.api.resetPassword(this.state.currentPassword, this.state.newPassword).then(res=>{
                                if (res.status === 'error') {
                                    toast.error(strings[res.message]);
                                } else {
                                    toast.success(strings.passwordChanged);
                                }
                            });
                        }
                    }} className={'btn-save'}>{strings.changeButton}</button>
                </div>
            </div>
        </div>
    };
}
