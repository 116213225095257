import {Component} from "react";
import {Link} from "react-router-dom";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        cams: 'Cameras',
        analytics: 'Analytics',
        torlinAlarm: 'Mobile app'
    },
    cs: {
        cams: 'Kamery',
        analytics: 'Analytika',
        torlinAlarm: 'Mobilní aplikace'
    }
})

let _nav = [
    {link: '/', label: strings.cams, allow: 'menu-dashboard'},
    {link: '/analytics', label: strings.analytics, allow: 'menu-analysis'},
    {link: '/torlin_alarm', label: strings.torlinAlarm, allow: 'menu-mobileapp'},
]


export default class Nav extends Component {

    constructor(props) {
        super(props);
        let location = window.location.pathname
        this.state = {
            active: location
        }

        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(event) {
        this.setState({active: event.target.getAttribute("data-link")});
    }


    render() {
        return (
            <nav>
                <ul>
                    {_nav.map((value) => {
                        if (!this.props.api.user.isAllowed('Menu', value.allow)) return null;
                        return (
                            <li key={value.link}>
                                <Link onClick={this.handleClick} to={value.link} data-link={value.link}
                                      className={(this.state.active === value.link) ? 'item-act' : ''}>{value.label}</Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        )
    }
}
