import {useDispatch, useSelector} from "react-redux";
import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import * as React from 'react';
import {useEffect} from "react";
import Loader from "../../libraries/Loader/Loader";
import {setNeedUpdate} from "../../ReduxStores/Dolak";
import DolakTables from "./DolakTables";
import DolakTableDetail from "./DolakTableDetail";

let updateData;

export default function DolakDataPresenter(props) {
    const {api} = props;
    const dispatch = useDispatch();

    const season = useSelector(state=>state.dolak.season)
    const days = useSelector(state=>state.dolak.days)
    const hours = useSelector(state=>state.dolak.hours)
    const needUpdate = useSelector(state=>state.dolak.needUpdate)
    const detailView = useSelector(state=>state.dolak.detailView)

    const [loaded, setLoaded] = React.useState(false);
    const [tables, setTables] = React.useState([]);
    const [cars, setCars] = React.useState([]);
    const [showNobody, setShowNobody] = React.useState(true)

    function callApiUpdateDataTimeout() {
        if (needUpdate || loaded === false) {
            dispatch(setNeedUpdate(false));
            clearTimeout(updateData)
            updateData = setTimeout(callApiUpdate, 500);
        }

    }

    function callApiUpdate() {
        api.updateDolakData(season, days, hours).then(res=>{
            setTables(res.data.tables);
            setCars(res.data.cars);
            setLoaded(true);
        });
    }

    useEffect(callApiUpdateDataTimeout);

    if (loaded === false) return <Box sx={{mt:5}}><Loader /></Box>

    if (detailView) {
        if (detailView.section === 'table') {
            return <DolakTableDetail api={api} tableId={detailView.item} label={tables.label[detailView.item]} />
        }
    }

    return <Box sx={{mt: 5}}>
        <Typography variant={'h4'}>Analýza využití stolů</Typography>
        <FormControlLabel control={<Checkbox checked={showNobody} onChange={event=>setShowNobody(event.target.checked)} />} label="Zobrazit prázdné stoly" />
        <DolakTables showNobody={showNobody} data={tables} cars={false} />
        <Typography sx={{mt:5}} variant={'h4'}>Analýza využití výdeje aut</Typography>
        <DolakTables data={cars} cars={true} />
    </Box>
}