import React from 'react';
import {useTable, useFilters, useSortBy, usePagination} from 'react-table'
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        search: 'Search',
        records: 'records',
        page: 'Page',
        of: 'of',
        of_small: 'of',
        Show: 'Show',
        gotopage: 'Go to page',
        showOnPage: 'Show on page'
    }, cs: {
        search: 'Prohledat',
        records: 'záznamy',
        page: 'Stránka',
        of_small: 'ze',
        of: 'z',
        Show: 'Zobrazit',
        gotopage: 'Skočit na stranu',
        showOnPage: 'Zobrazit na stránku'
    }
})


// Define a default UI for filtering
export function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter}}) {
    const count = preFilteredRows.length;

    return (
        <div className={'th-input'}><input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${strings.search} ${count} ${strings.records}...`}/></div>
    )
}


export default function TorlinTable({columns, data}) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            // fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        nextPage,
        pageCount,
        prepareRow,
        canPreviousPage,
        previousPage,
        canNextPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable({
        columns,
        data,
        initialState: {pageIndex: 0, pageSize: 20},
        filterTypes,
        defaultColumn,
    }, useFilters, useSortBy, usePagination);

    return (
        <div className={'torlin-table'}>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.canSort ? <div className={'th-sort'}>
                                    <strong className={'th-name'}>{column.render('Header')}</strong>
                                    <span
                                        className={column.isSorted ? column.isSortedDesc ? 'btn-sort-down' : 'btn-sort-up' : 'btn-sort'}>{column.isSorted ? column.isSortedDesc ? 'down' : 'up' : ''}</span>
                                    {column.canFilter ? column.render("Filter") : null}
                                </div> : <strong className={'th-name'}>{column.render('Header')}</strong>}
                            </th>
                        })
                        }
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>

            {data.length>20?
            <div className="pagination">
                <div className={'pager'}>
                    <button className={'btn-prev'} onClick={() => previousPage()} disabled={!canPreviousPage}/>
                    <span> {strings.page}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            max={pageCount}
                            min={1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}/>
                        {pageCount < 5 ? pageCount<2?strings.of:strings.of_small : strings.of} {pageCount}
                        <button className={'btn-next'} onClick={() => nextPage()} disabled={!canNextPage}/>
                    </span>
                </div>
                <div className={'on-page'}>
                    {strings.showOnPage}
                    <select
                        value={pageSize}
                        onChange={e => {setPageSize(Number(e.target.value))}}>
                        {[20, 40, 80, 100, 200].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>:null}
        </div>
    )
}
