import * as React from "react";
import dayjs from "dayjs";
import {Autocomplete, Box, Checkbox, Stack, TextField, Typography} from "@mui/material";
import TorlinDateRangePicker from "../../libraries/Datepicker/Datepicker";
import {TimePicker} from "@mui/x-date-pickers";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useDispatch, useSelector} from "react-redux";
import {setDays, setHours, setSeason} from "../../ReduxStores/Dolak";
import Loader from "../../libraries/Loader/Loader";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const days = [
    {value: 0, label: 'pondělí', short: 'po'},
    {value: 1, label: 'úterý', short:'út'},
    {value: 2, label: 'středa', short:'st'},
    {value: 3, label: 'čtvrtek', short:'čt'},
    {value: 4, label: 'pátek', short:'pá'},
    {value: 5, label: 'sobota', short:'so'},
    {value: 6, label: 'neděle', short:'ne'}
];

export default function DolakSeasonSelect() {
    const minDate = new Date('2023-10-31');
    const maxDate = new Date();
    const dispatch = useDispatch();

    const season = useSelector(state=>state.dolak.season)
    const seasonDays = useSelector(state=>state.dolak.days)
    const hours = useSelector(state=>state.dolak.hours)

    if (season.from === null) {
        const from = dayjs().add(-8, 'day').format('YYYY-MM-DD');
        const till = dayjs().add(-1, 'day').format('YYYY-MM-DD')
        dispatch(setSeason({from: from, till: till}))
    }
    if (seasonDays.length === 0) {
        dispatch(setDays([days[0], days[1], days[2],days[3], days[4]]))
    }
    if (hours.from === null) {
        dispatch(setHours({from: '07:00', till: '19:00'}))
    }

    if (season.from === null) {
        return <Loader />
    }

    return <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
            m: 1,
        },
    }}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant={'h6'}>Výběr období</Typography>
                <TorlinDateRangePicker
                    onChanged={value=>{
                        dispatch(setSeason({from: dayjs(value.startDate).format('YYYY-MM-DD'), till: dayjs(value.endDate).format('YYYY-MM-DD')}))
                    }}
                    startDate={new Date(season.from)}
                    endDate={new Date(season.till)}
                    dateMin={minDate} dateMax={maxDate} />
            </Stack>
            <Stack direction={'column'} spacing={2} sx={{width: 500}}>
                <Typography variant={'h6'}>Výběr dnů</Typography>
                <Autocomplete
                    multiple
                    id="day-select"
                    options={days}
                    value={seasonDays}
                    onChange={ (event, value)=>{
                        function compare( a, b ) {
                            if ( a.value < b.value ){
                                return -1;
                            }
                            if ( a.value > b.value ){
                                return 1;
                            }
                            return 0;
                        }

                        value.sort( compare );
                        dispatch(setDays(value))
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.short}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    style={{ maxWidth: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Zvolené dny" placeholder="Zvolené dny" />
                    )}
                />
            </Stack>
            <Stack direction={'column'} spacing={2}>
                <Typography variant={'h6'}>Výběr času</Typography>
                <TimePicker label="Čas od" onChange={value=>dispatch(setHours({from: value.format('HH:mm'), till: hours.till}))} ampm={false} value={dayjs('1985-10-23T'+hours.from)} />
                <TimePicker label="Čas do" onChange={value=>dispatch(setHours({from: hours.from, till: value.format('HH:mm')}))} ampm={false} value={dayjs('1985-10-23T'+hours.till)} />
            </Stack>
    </Box>
}