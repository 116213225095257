import React, {Component} from "react";
import {ResponsiveBar} from "@nivo/bar";
import LocalizedStrings from "react-localization";
import TorlinTable from "../../libraries/TorlinTable/TorlinTable";

const strings = new LocalizedStrings({
    en: {
        footfall: 'Footfall',
        room: 'Area'
    },
    cs: {
        footfall: 'Footfall',
        room: 'Oblast'
    }
})

const theme = {
    "textColor": "white",
    "fontSize": 11,
    "axis": {
        "domain": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 1
            }
        },
        "ticks": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 1
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#2c2c2c",
            "strokeWidth": 1
        }
    },
    tooltip: {
        container: {
            background: 'black'
        }
    },
    label: {
        text: {
            fill: 'yellow'
        }
    }
}

export default class FootfallData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            windowWidth: 360
        }
        this.chartRef = React.createRef();

    }
    updateDimensionOverall = () => {
        this.setState({windowWidth: this.chartRef.current.offsetWidth})
    }
    componentDidMount() {
        this.setState({windowWidth: this.chartRef.current.offsetWidth})
        window.addEventListener('resize', this.updateDimensionOverall);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensionOverall)
    }
    render () {
        let footfall_data = [];

        let filter = [];
        if (this.props.filter) {
            for (let row in this.props.filter) {
                filter.push(this.props.filter[row].value)
            }
        }

        let counter=0;
        for (let roi_id in this.props.data) {
            let room = this.props.data[roi_id];
            if (filter.length) {
                // if (room.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) === -1) continue;
                if (filter.indexOf(roi_id) === -1) continue;
            }
            footfall_data.push({room: room.name, "footfall": room.count, rid: room})
            counter++;
        }
        let graphWidth = Math.max(this.state.windowWidth, (12*(counter-1)+70))

        let columns = [{id: 'room', accessor: 'room', Header: strings.room},{id: 'count', accessor: 'footfall', Header: strings.footfall, disableFilters: true}]

        return  <div className={'analytics-data'}>
            <div className={'chart-overall'} ref={this.chartRef}>
            <div className={'chart'}><ResponsiveBar
            data={footfall_data}
            keys={[ 'footfall' ]}
            indexBy="room"
            margin={{ top: 50, right: 0, bottom: 150, left: 60 }}
            width={graphWidth}
            axisBottom={{
                tickSize: 4,
                tickPadding: 30,
                tickRotation: 90,
                legend: strings.room,
                legendPosition: 'middle',
                legendOffset: 15
            }}
            colors={{scheme: 'category10'}}
            colorBy={'indexValue'}
            label={{
                skipWidth: 100
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: strings.footfall,
                legendPosition: 'middle',
                legendOffset: -50
            }}
            tooltipLabel={(e)=>{return e.indexValue}}
            theme={theme}
            onClick={(e)=>{this.props.callbackDetail({value: String(footfall_data[e.index].rid.rid), label:footfall_data[e.index].room})}}
            /></div></div>
            <TorlinTable
                className={'table'}
                data={footfall_data} columns={columns}
            />
        </div>
    }
}
