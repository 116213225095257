import {Component} from "react";
import {Link} from "react-router-dom";
import LocalizedStrings from "react-localization";


let strings = new LocalizedStrings({
    en: {
        cams: 'Cameras'
    },
    cs: {
        cams: 'Kamery'
    }
})

export default class Breadcrumbs extends Component {
    render() {
        return <div className="breadcrumbs">
            <ul>
                <li><Link to={'/'}>{strings.cams}</Link></li>
                <li><span>{this.props.data}</span></li>
            </ul>
        </div>
    }
}
