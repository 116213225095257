import {Component} from "react";
import Nav from "./Nav";
import {Badge, IconButton} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import {Logout, Settings} from "@mui/icons-material";


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unreadCount: 0
        }
    }
    componentDidMount() {
        this.updateUnreadCount();
    }

    updateUnreadCount() {
        this.props.api.getUnreadMailCount().then(res=>{
            this.setState({unreadCount: res.unread_count})
        })
    }

    render() {
        return (
            <header>
                <div className="in">
                    <h1><a href="/">TORLIN</a></h1>
                    <Nav {...this.props} />

                    <div className="user-nav">
                        <div className="user-nav-detail">
                            <span className={'username'}>{this.props.api.user.getLabel()}</span>
                            <IconButton aria-label={'settings'} onClick={()=>{
                                this.props.router.current.history.push('/settings')
                            }}>
                                <Settings />
                            </IconButton>
                            <IconButton size={'large'} aria-label={'news'} onClick={()=>{
                                this.props.router.current.history.push('/mails')
                            }}>
                                <Badge badgeContent={this.state.unreadCount} color={"primary"}>
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton aria-label={'logout'} onClick={this.props.onLogout}>
                                <Logout />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
