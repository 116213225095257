import React from 'react';
import ReactDOM from "react-dom/client";
import './less/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './ReduxStores/Store'
import {Provider} from "react-redux";



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Provider store={store}><App /></Provider>);

/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Object.defineProperty(Date.prototype,'getTTime', {
    get: function() {
        return this.getFullYear()+'-'+("0" + (this.getMonth() + 1)).slice(-2)+'-'+this.getDate()+'T'+('0' + this.getHours()).slice(-2) + ':' + ('0' + this.getMinutes()).slice(-2);
    }
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
