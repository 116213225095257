import {Component} from "react";
import LocalizedStrings from "react-localization";
import Loader from "../../libraries/Loader/Loader";

let strings = new LocalizedStrings({
    en: {
        person: 'Person',
        light: 'Light',
        car: 'Car',
        animal: 'Animal',
        other: 'Other',
        previous: 'Previous',
        next: 'Next',
        today: 'Today',
        yesterday: 'Yesterday',
        from: 'From',
        till: 'Till',
        showOnlyValid: 'Show only valid events',
        show: 'show'
    },
    cs: {
        person: 'Osoba',
        light: 'Světlo',
        car: 'Auto',
        animal: 'Zvíře',
        other: 'Ostatní',
        previous: 'Předchozí',
        next: 'Další',
        today: 'Dnes',
        yesterday: 'Včera',
        from: 'Od',
        till: 'Do',
        showOnlyValid: 'Zobrazit pouze validované',
        show: 'Zobrazit'
    }
})

export default class CamDetailRightPanel extends Component {

    // diff time to DateSelect in init. Till is last record (often "now"), from is this limit
    diff_time_limit = 1000*60*60;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            records: [],
            only_validated: false,
            events: {},
            from: this.props.from,
            till: this.props.till,
            showPanel: false,
            recDateMin: null,
            recDateMax: null,
            isLive: false
        }

        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    componentDidMount() {
        this.props.api.loadRecordInfo(this.props.camera_id).then(res=>{
            let records = [...res.records]; let record; let till = this.state.till; let from = this.state.from; let diff;
            if (!from || !till) {
                while ((record = records.pop())) {
                    if (!till) {
                        till = (record.till)?new Date(record.till):new Date();
                    }
                    from = new Date(record.from);
                    diff = Math.abs(till-from);
                    if (diff>(this.diff_time_limit)) break;
                }
            }
            let validated = this.isShownInvalidate(from, till, res.events);
            this.setState({
                loading: false,
                events: res.events,
                only_validated: validated,
                from: from,
                futureFrom: from,
                till: till,
                futureTill: till,
                records: res.records,
                recDateMin: res.min.replace(' ','T').substr(0,16),
                recDateMax: res.max.replace(' ','T').substr(0,16)
            })
            this.props.callbackChangeTimeline(from, till);
        })
    }


    handleNextClick(event) {
        event.preventDefault();
        let from; let till;
        let records = [...this.state.records]; let record;
        while ((record = records.shift())) {
            if (!from || (from<this.state.till)) {
                from = new Date(record.from);
                continue;
            }
            if (record.till === null) {
                till = new Date();
                break;
            }
            till = new Date(record.till);
            if ((till-from)>this.diff_time_limit) break;
        }
        if (till && from) {
            this.props.callbackChangeTimeline(from, till);
            let showInvalidate = this.isShownInvalidate(from, till);
            this.setState({
                from: from,
                futureFrom: from,
                till: till,
                futureTill: till,
                only_validated: showInvalidate
            })
        }
    }


    handlePrevClick(event) {
        event.preventDefault();
        let from; let till;
        let records = [...this.state.records]; let record;
        while ((record = records.pop())) {
            if (record.till === null) continue;
            if (till === undefined) till = new Date(record.till);

            if (till>this.state.from) {
                till = new Date(record.till);
                continue;
            }
            from = new Date(record.from);
            if ((till-from)>this.diff_time_limit) {break}
        }
        if (till && from) {
            this.props.callbackChangeTimeline(from, till);
            let showInvalidate = this.isShownInvalidate(from, till);
            this.setState({
                from: from,
                futureFrom: from,
                till: till,
                futureTill: till,
                only_validated: showInvalidate
            })
        }
    }


    isShownInvalidate(from, till, events) {
        return true;
        if (!events) events = this.state.events;
        let counter = 0;
        for (let i=0;i<events.length;i++) {
            if (!events[i].validated) continue;
            let time = new Date(events[i].time);
            if (time<till && time>from) {
                counter++;
            }
        }
        return (counter>10);
    }


    render() {
        if (this.state.loading) {
            return <Loader/>
        }
        if (this.state.from === null) {
            return <div className="record-aside">&nbsp;</div>
        }
        let events_div;
        if (this.state.events.length) {
            console.log('events');
            events_div = <ul>{this.state.events.map(event=>{
                    if (this.state.only_validated && !event.validated) return null;
                    let event_time = new Date(event.time);
                    if (event_time>this.state.futureTill) return null;
                    if (event_time<this.state.futureFrom) return null;
                    let clickable = ( (event_time<this.state.till) && (event_time>this.state.from) )
                    let ico_class = (event.class)?"ico-"+event.class:"ico-other";
                    let time_string = ("0" + event_time.getHours()).slice(-2) + ":" + ("0" + event_time.getMinutes()).slice(-2)
                        + (event.pid!==undefined?':'+("0" + event_time.getSeconds()).slice(-2):'');
                    return (
                        <li key={"event_id_"+event.id}>
                            <TorlinEventHref clickable={clickable} data_time={event.time} ico_class={ico_class} time={time_string} text={strings[event.class]} callback={this.props.callbackMoveOnTimeLine} />
                        </li>
                    )
                })}</ul>
        } else {
            let events = [];
            let start = new Date();
            let past = start.getDate()-7;
            start.setDate(past);
            let end = new Date();
            for (let i=0;i<15;i++) {
                let date=new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
                let rand = Math.floor(Math.random()*4);
                let ico_classes = ['ico-person', 'ico-car', 'ico-animal', 'ico-other'];
                let ico_class = ico_classes[rand];
                let time_string = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2)
                events.push(<li key={"event_id_"+i}><TorlinEventHref clickable={true} data_time={date.getTime()} ico_class={ico_class} time={time_string} text={strings[ico_class]} callback={this.props.callbackMoveOnTimeLine} /></li>);
            }
            events_div =
                <ul className={'no-items-list'}>
                    {events}
                    <div className={'no-items'}>
                        Nevyužíváte služby <strong>analýza obrazu</strong>, díky které můžete mít veškeré události přehledně roztříděné.
                        <button>Vyzkoušet</button>
                    </div>
                </ul>
        }
        let now = new Date();
        let yesterday = new Date(Date.now() - 864e5);
        let day_string = (now.toDateString() === this.state.from.toDateString()) ? strings.today : (yesterday.toDateString() === this.state.from.toDateString()) ? strings.yesterday : this.state.from.toLocaleDateString();
        let live_info = (this.state.isLive) ? <div className="record-aside-live-info"><h3>Probíhá živý přenos</h3><button>Zobrazit záznamy</button></div> : null;
        return (
            <aside className={'record-aside' + (this.state.isLive?' record-aside-hide':'')}>
                {live_info}
                <div className="record-aside-action">
                    <a onClick={this.handlePrevClick} href="/" className="btn-prev">{strings.previous}</a>
                    <a onClick={(event)=>{event.preventDefault();this.setState({showPanel: !this.state.showPanel})}} href="/" className={'btn-select-date'+(this.state.showPanel?' active':'')}>
                        <strong>{day_string}</strong>
                        <span>{("0" + this.state.from.getHours()).slice(-2) + ":" + ("0" + this.state.from.getMinutes()).slice(-2)} -
                        {("0" + this.state.till.getHours()).slice(-2) + ":" + ("0" + this.state.till.getMinutes()).slice(-2)}</span>
                        <i className="ico-edit" />
                    </a>
                    <a onClick={this.handleNextClick}  href="/" className="btn-next">{strings.next}</a>
                </div>
                <div className={'record-aside-settings'+(this.state.showPanel?'':' hidden')}>
                    <div className="record-aside-settings-content">
                        <div className="row-from">
                            <span>{strings.from}:</span>
                            <div className="row-item-date">
                                <input
                                    type="datetime-local"
                                    min={this.state.recDateMin}
                                    max={this.state.recDateMax}
                                    defaultValue={this.state.from.getTTime}
                                    onChange={(event)=>{
                                           let newDate = new Date(event.target.value);
                                           this.setState({
                                               futureFrom: newDate
                                           })
                                       }}/>
                            </div>
                        </div>
                        <div className="row-to">
                            <span>{strings.till}:</span>
                            <div className="row-item-date">
                                <input type="datetime-local"
                                       min={this.state.recDateMin}
                                       max={this.state.recDateMax}
                                       defaultValue={this.state.till.getTTime}
                                       onChange={(event)=>{
                                           let newDate = new Date(event.target.value);
                                           this.setState({
                                               futureTill: newDate
                                           })
                                       }}
                                />
                            </div>
                        </div>
                        <label className="item-checkbox">
                            <input onClick={()=>{this.setState({only_validated: !this.state.only_validated})}} type="checkbox" name="only-valid" id="only-valid" checked={this.state.only_validated} />
                            <i className="like-checkbox" />
                            {strings.showOnlyValid}
                        </label>
                        <button type="submit" className="btn-blue-small"
                            onClick={()=>{
                                this.props.callbackChangeTimeline(this.state.futureFrom, this.state.futureTill);
                                this.setState({
                                    from: this.state.futureFrom,
                                    till: this.state.futureTill,
                                    showPanel: false
                                })
                            }}
                            >{strings.show}</button>
                    </div>
                </div>
                <div className="record-aside-list">{events_div}</div>
            </aside>
        )
    }
}


class TorlinEventHref extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visited: false
        }
        this.handleEventClick = this.handleEventClick.bind(this);
    }

    handleEventClick(event) {
        event.preventDefault();
        if (!this.props.clickable) return;
        this.setState({visited: true})
        this.props.callback(this.props.data_time)
    }

    render() {
        return (
            <a onClick={this.handleEventClick} href="/" className={(this.state.visited?"visited":"")+(this.props.clickable?'':'inactive')}>
                <i className={this.props.ico_class}/>
                <span className="date">{this.props.time}</span>
                <span className="info">{this.props.text}</span>
            </a>
        )
    }
}
