import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import Select from 'react-select'
import CamSelectList from "./CamSelectList";
import LocalizedStrings from "react-localization";
import {toast} from "react-toastify";
import {reactSelectDarkTheme} from "../../libraries/ReactSelect/DarkTheme";
import AlertSelectList from "./AlertSelectList";

let strings = new LocalizedStrings({
    en: {
        'error-not-enough-strong-password': 'Password need to be 8 chars and include lower case, upper case, number and special char',
        'error-username-exists': 'Username is not available',
        'error-cant-edit-other-users': 'You have no right to edit users.',
        cantEditUser: 'You cant edit this user',
        username: 'Username',
        password: 'Password',
        confirmPassword: 'Confirm password',
        name: 'Name',
        surname: 'Surname',
        email: 'Email',
        roles: 'Roles',
        parent: 'User\'s admin'
    },
    cs: {
        'error-not-enough-strong-password': 'Pro Vaši bezpečnost je nutné zadat bezpečné heslo. Musí obsahovat malé i velké písmeno, číslici a jeden speciální znak.',
        'error-username-exists': 'Toto uživatelské jméno není k dispozici.',
        'error-cant-edit-other-users': 'Nemáte právo na úpravu uživatelů.',
        cantEditUser: 'Nemůžete editovat tohoto uživatele',
        username: 'Přihlašovací jméno',
        password: 'Heslo',
        confirmPassword: 'Potvrzení hesla',
        name: 'Jméno',
        surname: 'Přijmení',
        email: 'Email',
        roles: 'Role',
        parent: 'Uživatelův administrátor'
    }
})

export default class UserForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
            email: null,
            name: null,
            phone: null,
            roles: [],
            surname: null,
            username: null,
            password: '****',
            confirmPassword: '****',
            parent: null,
            users_list: [],
            passwordValid: true,
            roles_list: [],
            places_loaded: false,
            places: [],
            loaded: false,
        };

        this.list = null;
        this.alerts = null;

        if (this.props.user_id) {
            this.state.loading = true;
        }
    }


    componentDidMount() {
        if (this.props.user_id) {
            this.props.api.loadUserInfo(this.props.user_id).then(res=>{
                if (res.status === 'ok') {
                    let roles = res.roles;
                    let form_data = [];
                    let roles_form = [];
                    for (let role in roles) {
                        form_data.push({value: roles[role].id, label: roles[role].name})
                        if (res.data.roles.indexOf(roles[role].id) !== -1) {
                            roles_form.push({value: roles[role].id, label: roles[role].name})
                        }
                    }
                    let users_list = []; let parent;
                    for (let user in res.users) {
                        let value = {value: res.users[user].id, label: res.users[user].username};
                        if (res.data.parent === res.users[user].id) {
                            parent = value;
                        }
                        users_list.push(value)
                    }
                    this.setState({
                        avatar: res.data.avatar,
                        email: res.data.email,
                        name: res.data.name,
                        phone: res.data.phone,
                        roles: res.data.roles,
                        roles_list: form_data,
                        roles_form: roles_form,
                        surname: res.data.surname,
                        username: res.data.username,
                        parent: parent,
                        users_list: users_list,
                        loaded: true
                    })
                } else {
                    toast.error(strings.cantEditUser);
                    this.props.cancelCallback();
                }
            });
        } else {
            this.props.api.getRolesList().then(res=>{
                let roles = res;
                let form_data = [];
                for (let role in roles) {
                    form_data.push({value: roles[role].id, label: roles[role].name})
                }
                this.setState({
                    roles_list: form_data,
                    password: '',
                    confirmPassword: '',
                    loaded: true
                })
            });
        }
    }


    render() {
        if (this.state.loaded === false) return <Loader />

        return <div className="settings">
            <div className="settings-form">
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.username}:</span>
                        <input type="text" value={this.state.username} onChange={(elm)=>{this.setState({username: elm.target.value})}}/>
                    </label>
                </div>
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.password}:</span>
                        <input type="password" value={this.state.password} onChange={(elm)=>{
                            let passwordValid = (elm.target.value === this.state.confirmPassword)
                            this.setState({password: elm.target.value, passwordValid: passwordValid})}}/>
                    </label>
                </div>
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.confirmPassword}:</span>
                        <input className={this.state.passwordValid?'valid':'invalid'} type="password" value={this.state.confirmPassword} onChange={(elm)=>{
                            let passwordValid = (elm.target.value === this.state.password)
                            this.setState({confirmPassword: elm.target.value, passwordValid: passwordValid})}}/>
                    </label>
                </div>
                <div/>
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.name}:</span>
                        <input type="text" value={this.state.name} onChange={elm=>{this.setState({name: elm.target.value})}}/>
                    </label>
                </div>
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.surname}:</span>
                        <input type="text" value={this.state.surname} onChange={elm=>{this.setState({surname: elm.target.value})}}/>
                    </label>
                </div>

                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.email}:</span>
                        <input type="email" value={this.state.email} onChange={elm=>{this.setState({email: elm.target.value})}}/>
                    </label>
                </div>
                <div/>
                <div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.roles}:</span>
                        <Select styles={reactSelectDarkTheme}
                                value={this.state.roles_form}
                                className={'react-select'}
                                options={this.state.roles_list}
                                isMulti={true}
                                onChange={elm=>{this.setState({roles_form: elm})}}/>
                    </label>
                </div>
                {this.props.api.user.isAllowed('Acl','change-useradmin')?<div className="form-item-4">
                    <label htmlFor="">
                        <span>{strings.parent}:</span>
                        <Select styles={reactSelectDarkTheme}
                                value={this.state.parent}
                                className={'react-select'}
                                options={this.state.users_list}
                                onChange={elm=>{this.setState({parent: elm})}}/>
                    </label>
                </div>:null}

                <CamSelectList {...this.props} ref={(ref)=>{this.list = ref}}/>
                <AlertSelectList {...this.props} ref={(ref)=>{this.alerts = ref}} currentUser={this.props.user_id}/>

                <div className="form-footer">
                    <button className="btn-gray" onClick={this.props.cancelCallback}>Zrušit</button>
                    <button disabled={!this.state.passwordValid} type="submit" className="btn-save" onClick={()=>{
                        let data = {
                            userId: this.props.user_id,
                            username: this.state.username,
                            password: this.state.password,
                            name: this.state.name,
                            surname: this.state.surname,
                            email: this.state.email,
                            roles: this.state.roles_form,
                            cams: this.list.getCheckedTree(),
                            alerts: this.alerts.getValues(),
                        }
                        if (this.props.api.user.isAllowed('Acl','change-useradmin')) {
                            if (this.state.parent) {
                                data['parent'] = this.state.parent.value;
                            }
                        }
                        this.props.api.editUser(data).then(res=>{
                            if (res.status === 'error') {
                                toast.error(strings['error-'+res.message]);
                            } else {
                                this.props.updateCallback(res.status);
                            }
                        })
                    }}>Uložit</button>
                </div>
            </div>
        </div>
    }
}
