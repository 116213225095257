import {Box, Divider} from "@mui/material";
import DolakSeasonSelect from "./DolakSeasonSelect";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DolakDataPresenter from "./DolakDataPresenter";

require('dayjs/locale/cs')
dayjs.locale('cs')

export default function Dolak(props) {
    return <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DolakSeasonSelect />
            <Divider sx={{mt: 5}} />
            <DolakDataPresenter {...props} />
        </LocalizationProvider>
    </Box>
}