import {Component} from "react";
import UserListTable from "./UserListTable";
import Loader from "../../libraries/Loader/Loader";
import Account from "../Account/Account";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import LocalizedStrings from "react-localization";
import RolesList from "./RolesList";
import RulesSettings from "./RulesSettings";
import Mails from "../Mails/Mails";

let strings = new LocalizedStrings({
    en: {
        'user-password': 'Change password',
        'settings-users-list': 'List of users',
        'settings-roles-list': 'List of Roles',
        'settings-rules-list': 'List of Rules',
        'mails': 'Mails',
    },
    cs: {
        'user-password': 'Změna hesla',
        'settings-users-list': 'Seznam uživatelů',
        'settings-roles-list': 'Role',
        'settings-rules-list': 'Práva',
        'mails': 'Zprávy',
    }});

export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tabs: [],
        }
    }


    render() {
        if (this.state.loading) return <Loader/>

        let tabs_string = [];
        let tabs_content = [];
        let tabs_source = {
            'user-password': <Account api={this.props.api} />,
            'settings-roles-list': <div className={'roles-list'}><RolesList {...this.props} /></div>,
            'settings-users-list': <div className={'users-list'}><UserListTable {...this.props} /></div>,
            'settings-rules-list': <div className={'users-list'}><RulesSettings {...this.props} /></div>,
            'mails': <div className={'mails'}><Mails {...this.props} /></div>,

        }
        for (let index in this.state.tabs) {
            let tab = this.state.tabs[index];
            tabs_string.push(<Tab key={tab}>{strings[tab]}</Tab>)
            if (tabs_source[tab]) {
                tabs_content.push(<TabPanel key={'tab-conetent'+tab}>{tabs_source[tab]}</TabPanel>);
            } else {
                tabs_content.push(<TabPanel key={'tab-conetent'+tab}>No content</TabPanel>);
            }
        }

        return (
            <div>
                <Tabs>
                    <TabList>
                        {tabs_string}
                    </TabList>
                    {tabs_content}
                </Tabs>
            </div>
        )
    }

    editUser(value) {
        this.setState({editUser: value});
    }


    componentDidMount() {
        this.props.api.getAllowedSettings().then(res=>{
            this.setState({
                loading: false,
                tabs: res.allowed
            })
        });
    }
}
