const backgroundColor = '#0F1114';
const valueBackgroundColor = '#222a2d'
const valueColor = '#FFF';

export const reactSelectDarkTheme  = {
    menu: (provided,state) => ({
        ...provided,
        backgroundColor: backgroundColor,
        color: valueColor,
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => (
        {
            ...provided,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? valueBackgroundColor
                    : isFocused
                        ? valueBackgroundColor
                        : null,
            color: valueColor,
            ':active': {
                ...provided[':active'],
                backgroundColor: valueBackgroundColor
            },
        }
    ),
    control: (provided,state) => ({
        ...provided,
        backgroundColor: backgroundColor,
        border: backgroundColor
    }),
    indicatorSeparator: (provided,state) => ({
        ...provided,
        backgroundColor: backgroundColor
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: valueBackgroundColor,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: valueColor,
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: valueColor,
    })
}
