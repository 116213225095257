import {Component} from "react";
import Loader from "../../libraries/Loader/Loader";
import {toast} from "react-toastify";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        saved: 'Successfully saved',
        save: 'Save'
    },
    cs: {
        saved: 'Úspěšně uloženo',
        save: 'Uložit'
    }
})

export default class RulesSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            rules: {}
        }

        this.changeCallback = this.changeCallback.bind(this);
    }

    componentDidMount() {
        this.props.api.getRulesTree().then(res=>{
            this.setState({loaded: true, rules: res.rules})
        })
    }

    changeCallback(index, resource, rule, value, allow) {
        let new_value = (value)?(allow==='allow')?1:0:null;
        let rules = this.state.rules;
        rules[index]['resources'][resource][rule]=new_value;
        this.setState({rules: rules});
    }

    render() {
        if (this.state.loaded) {
            return <div className={'settings'}>
                <div className={'rules-tree'}>
                    <TreeView changeCallback={this.changeCallback} data={this.state.rules} />
                </div>
                <div className="form-footer">
                    <button type="submit" className="btn-save" onClick={()=>{
                            this.props.api.saveRulesTree(this.state.rules).then(res=>{
                                if (res.status === 'ok') {
                                    toast.success(strings.saved)
                                } else {
                                    toast.error(res.message);
                                }
                            })
                        }}>{strings.save}</button>
                </div>
            </div>
        } else {
            return <Loader />
        }
    }
}

class TreeView extends Component {
    render() {
        return <ul className={'treeView'}>
            {Object.keys(this.props.data).map(index=> {
                let role = this.props.data[index];
                return <TreeNode changeCallback={(resource, rule, value, allow)=>{
                    this.props.changeCallback(index, resource, rule, value, allow);
                }} key={'rolenode-'+index} data={role} childrenIndex={'resources'}/>
            })}
        </ul>
    }
}

class TreeNode extends Component {
    constructor(props) {
        super(props);
        this.nested = (this.props.nested)?this.props.nested:1;
    }

    state = {
        collapse: (this.props.collapse)
    }

    render() {
        let sign = this.props.data[this.props.childrenIndex]?this.state.collapse ? '-':'+':'';
        let resources;
        if (this.nested === 2) {
            resources = this.state.collapse?<table onClick={e=>e.stopPropagation()}><thead><tr><th>name</th><th>allowed</th><th>deny</th></tr></thead>{Object.keys(this.props.data[this.props.childrenIndex]).map(rule=>{
                let allow_values = this.props.data[this.props.childrenIndex][rule];
                return <tr><td>{rule}</td>
                    <td><input onChange={(elm)=>{this.props.changeCallback(rule,elm.target.checked, 'allow')}} checked={allow_values === 1} type={'checkbox'}/></td>
                    <td><input onChange={(elm)=>{this.props.changeCallback(rule,elm.target.checked, 'deny')}} checked={allow_values === 0} type={'checkbox'} /></td>
                </tr>
            })}</table>:null;
        } else {
            resources = this.props.data[this.props.childrenIndex]?this.state.collapse?<ul>{Object.keys(this.props.data[this.props.childrenIndex]).map(index=>{
                let child = this.props.data[this.props.childrenIndex][index];
                return <TreeNode changeCallback={(rule, value, allow)=>{this.props.changeCallback(index, rule, value, allow)}} key={'node-'+this.props.nested+'_'+index} data={{name: index, rules:child}} childrenIndex={'rules'} nested={(this.nested+1)} />
            })}</ul>:null:null;
        }
        return <li onClick={(elm)=>{
            this.setState({collapse: !this.state.collapse});
            elm.stopPropagation()
            return true;
        }} className={'treeNode nested-'+this.nested+(sign?' collapsable':' notcollapsable')}>{sign} {this.props.data.name} {resources}</li>
    }
}
