import {Component} from "react";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        support: 'Support'
    },
    cs: {
        support: 'Technická podpora'
    }
})

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openContact: false
        }

        this.toggleContact = this.toggleContact.bind(this);
    }

    toggleContact() {
        this.setState({openContact: !this.state.openContact})
    }

    render() {
        return (
            <footer>
                <div onClick={this.toggleContact} className={'support active'+(this.state.openContact?'':'_')}>
                    <button className="support-btn">{strings.support}</button>
                    <div className="support-content">
                        <h3>Nevíte si rady? Rádi vám pomůžeme.</h3>
                        <div className="support-content-type">
                            <a href="tel:+420277771103" className="support-type-phone">
                                +420 277 771 103
                            </a>
                            <a href="mailto:podpora@torlin.cz" className="support-type-email">
                                podpora@torlin.cz
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
