import {Component} from "react";
import TorlinTable from "../../libraries/TorlinTable/TorlinTable";
import LocalizedStrings from "react-localization";
import Loader from "../../libraries/Loader/Loader";
import UserForm from "./UserForm";
import {toast} from "react-toastify";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';

let strings = new LocalizedStrings({
    en: {
        login: 'Login',
        first: 'First Name',
        last: 'Last Name',
        email: 'Email',
        options: 'Options',
        'update-message-updated': 'User successfully updated.',
        'update-message-no-update': 'User successfully updated.',
        newUser: 'New User',
        deleteUserTitle: 'Delete of user',
        deleteUserContent: 'Do you really want to delete this user?',
        cancel: 'Cancel',
        userDeleted: 'User was successfully deleted',
        delete: 'Delete',
        newUserSaved: 'User was saved',
        'error-not-allowed-to-delete-user': 'You have no rights to delete users',
        roles: 'Roles'
    },
    cs: {
        login: 'Uživatelské jméno',
        first: 'Křestní jméno',
        last: 'Přijmení',
        email: 'Email',
        options: 'Možnosti',
        'update-message-updated': 'Uživatel byl aktualizován.',
        'update-message-no-update': 'Žádné změny nebyly provedeny.',
        newUser: 'Nový uživatel',
        deleteUserTitle: 'Smazání uživatele',
        deleteUserContent: 'Opravdu chcete smazat tohoto uživatele?',
        cancel: 'Zrušit',
        userDeleted: 'Uživatel byl úspěšně smazán',
        delete: 'Smazat',
        newUserSaved: 'Nový uživatel byl uložen',
        'error-not-allowed-to-delete-user': 'Nemáte právo na mazání uživatelů.',
        roles: 'Role'
    }
})


export default class UserListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: false,
            view: 'list',
            editRow: false,
            columns: [
                {
                    Header: strings.login,
                    accessor: 'username',
                },
                {
                    Header: strings.roles,
                    accessor: 'roles_label',
                    Cell: ({value})=>{
                        return <>{value.join()}</>
                    }
                },{
                    Header: strings.first,
                    accessor: 'name'
                },{
                    Header: strings.last,
                    accessor: 'surname'
                },{
                    Header: strings.email,
                    accessor: 'email',
                    disableSortBy: true,
                    Filter: false,
                },
                {
                    Header: strings.options,
                    id: 'options',
                    accessor: 'id',
                    Cell: ({value}) => (<>
                            <button className={'btn-edit'} onClick={()=>{this.editRow(value);}}>Edit</button>
                            <Popup api={this.props.api} trigger={<button className={'btn-delete'}>Remove</button>} modal>
                                {close => (
                                    <div className="modal">
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="header"> {strings.deleteUserTitle} </div>
                                        <div className="content">
                                            {strings.deleteUserContent}
                                        </div>
                                        <div className="actions">
                                            <button
                                                className={'btn-danger'}
                                                onClick={()=>{
                                                    this.props.api.deleteUser(value).then(res=>{
                                                        if (res.status === 'error') {
                                                            toast.error(strings['error-'+res.message]);
                                                        } else {
                                                            toast.success(strings.userDeleted);
                                                            this.componentDidMount();
                                                            this.setState({view: 'list', editRow: null, loaded: false});
                                                            close();
                                                        }
                                                    })
                                                }}
                                            >
                                                {strings.delete}
                                            </button>
                                            <button
                                                className={'btn-cancel'}
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                {strings.cancel}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </>
                    ),
                    disableSortBy: true,
                    Filter: false,
                },
            ]
        }
    }

    componentDidMount() {
        this.props.api.loadUsersTable().then(res=>{
            if (res.status === 'ok') {
                this.setState({loaded: true, data: res.list})
            } else {
                toast.error(res.message);
            }
        })
    }

    editRow(value) {
        this.setState({view: 'editRow', editRow: value})
    }

    removeRow(value) {
        this.setState({view: 'deleteRow', editRow: value})
    }

    render() {
        if (this.state.loaded === false) return <Loader />

        if (this.state.view === 'editRow') {
            return <><UserForm api={this.props.api} user_id={this.state.editRow}
                               cancelCallback={()=>{this.setState({view: 'list', editRow: null})}}
                               updateCallback={(status)=>{
                                   this.componentDidMount();
                                   this.setState({view: 'list', editRow: null, loaded: false});
                                   toast.success(strings['update-message-'+status]);
                               }}/></>
        } else if(this.state.view === 'newRow') {
            return <><UserForm api={this.props.api}
                               cancelCallback={()=>{this.setState({view: 'list', editRow: null})}}
                               updateCallback={(status)=>{
                                   this.componentDidMount();
                                   this.setState({view: 'list', editRow: null, loaded: false});
                                   toast.success(strings.newUserSaved);
                               }}/></>
        } else {
            return <><button className={'btn btn-new'} onClick={()=>{this.setState({view: 'newRow'})}}>{strings.newUser}</button><TorlinTable columns={this.state.columns} data={this.state.data}/></>
        }
    }
}
