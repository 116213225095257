import axios from "axios";
import User from "../User/User";

//const API_URL = 'https://dev-backend.torlin.cz/';
const API_URL = 'https://backend.torlin.cz:8080/';

export default class Api {
    counter = 0;

    constructor(blurCallback) {
        this.user = new User()
        this.user.load()
        this.interface = axios.create({withCredentials: true, baseURL: API_URL})
        this.logout = this.logout.bind(this);
    }

    logout() {
        this.interface.post('acl/logout').then(() => {
            this.user.logout();
            window.location = '/';
        })
    }


    login(user) {
        this.user = user;
        return this.interface.post('acl/login', {user}).then(
            res => {
                let data = res.data;
                if (data.loginStatus === 'success') {
                    this.updateUser(data.user);
                    this.user.logged = true;
                    this.user.save();
                    window.location = '/';
                } else {
                    return false;
                }
            }
        )
    }

    updateUser(user) {
        if (user.username) {
            this.user.username = user.username;
        }
        if (user.language) {
            this.user.language = user.language;
        }
        if (user.firstname) {
            this.user.firstname = user.firstname;
        }
        if (user.surname) {
            this.user.surname = user.surname;
        }
        if (user.email) {
            this.user.email = user.email;
        }
        if (user.phone) {
            this.user.phone = user.phone;
        }
        if (user.roles) {
            this.user.roles = user.roles;
        }
        if (user.rules) {
            this.user.rules = user.rules;
        }
    }


    loadCameras() {
        return this.interface.post('cameras/get-list').then(res => {
            return this.controlResponse(res);
        })
    }


    loadAlerts(user_id) {
        return this.interface.post('cameras/get-alert-list',{user_id: user_id}).then(res => {
            return this.controlResponse(res);
        })
    }


    getCamerasByUser(user_id) {
        return this.interface.post('cameras/get-list', {user_id: user_id}).then(res => {
            return this.controlResponse(res);
        })
    }


    addFavCam(id) {
        this.interface.post('cameras/add-to-favorite', {camera_id: id}).then(res => {
            this.controlResponse(res)
        })
    }


    removeFavCam(id) {
        this.interface.post('cameras/remove-from-favorite', {camera_id: id}).then(res => {
            this.controlResponse(res)
        })
    }


    getOnlineStream(id) {
        return this.interface.post('stream/get-online-stream-url', {camera_id: id}).then(res => {
            return this.controlResponse(res)
        })
    }


    keepAlive(id) {
        return this.interface.post('stream/send-keep-alive', {camera_id: id}).then(res => {
            return this.controlResponse(res)
        })
    }


    loadCameraInfo(id) {
        return this.interface.post('cameras/get-camera-info', {camera_id: id, other_object: true}).then(res => {
            return this.controlResponse(res)
        })
    }

    loadRecordInfo(id) {
        return this.interface.post('cameras/get-record-info', {camera_id: id, with_events: true}).then(res => {
            return this.controlResponse(res)
        })

    }


    getRecordPlaylist(id, from, till) {
        return this.interface.post('cameras/get-record-playlist', {camera_id: id, from: from, till: till}).then(res => {
            return this.controlResponse(res)
        })
    }


    controlResponse(response) {
        if (response.status === 200) {
            let data = response.data;
            if (data === 'not_logged') {
                this.logout()
            }
            return response.data;
        } else {
            return false;
        }
    }


    loadUsersTable() {
        return this.interface.post('acl/users-list').then(res => {
            return this.controlResponse(res)
        })
    }


    getMobileDevices() {
        return this.interface.post('api/get-mobile-devices').then(res => {
            return this.controlResponse(res)
        })
    }


    saveMobileDevice(device_id, label, activated) {
        return this.interface.post('api/save-mobile-devices', {device_id: device_id, label: label, activated: activated}).then(res => {
            return this.controlResponse(res)
        })
    }


    deleteMobileDevice(device_id) {
        return this.interface.post('api/delete-mobile-device', {device_id: device_id}).then(res => {
            return this.controlResponse(res)
        })
    }


    loadUserInfo(user_id) {
        return this.interface.post('acl/user-info', {userId: user_id}).then(res => {
            return this.controlResponse(res)
        })
    }

    resetPassword(currentPassword, newPassword) {
        return this.interface.post('acl/reset-password', {current: currentPassword, new: newPassword}).then(res => {
            return this.controlResponse(res)
        })
    }

    downloadRecords(from, till) {
        return this.interface.post('stream/download-record', {from: from, till: till}, {responseType: "blob"}).then(res => {
            return res;
        })
    }

    getAllowedSettings() {
        return this.interface.post('acl/allowed-settings').then(res => {
            return this.controlResponse(res);
        })
    }

    editUser(data) {
        return this.interface.post('acl/edit-user-and-cams', {data}).then(res => {
            return this.controlResponse(res);
        })
    }

    getRolesList() {
        return this.interface.post('acl/roles-list').then(res => {
            return this.controlResponse(res);
        })
    }

    deleteUser(userId) {
        return this.interface.post('acl/delete-user', {userId: userId}).then(res => {
            return this.controlResponse(res);
        })
    }

    addOrUpdateRole(data) {
        return this.interface.post('acl/add-or-update-role', data).then(res => {
            return this.controlResponse(res);
        })
    }

    deleteRole(roleId) {
        return this.interface.post('acl/delete-role', {roleId: roleId}).then(res => {
            return this.controlResponse(res);
        })
    }

    getRulesTree() {
        return this.interface.post('acl/rules-tree').then(res => {
            return this.controlResponse(res);
        })
    }

    saveRulesTree(data) {
        return this.interface.post('acl/save-rules', data).then(res => {
            return this.controlResponse(res);
        })
    }


    getFootfallAnalytics(from, till) {
        return this.interface.post('analytics/footfall', {from: from, till: till}).then(res=>{
            return this.controlResponse(res)
        })
    }

    getFootfallDetail(stores, from, till) {
        return this.interface.post('analytics/footfall-detail', {from: from, till: till, stores: stores}).then(res=>{
            return this.controlResponse(res)
        })
    }

    getRoiSettingsTree() {
        return this.interface.post('analytics/roi-settings-tree').then(res=>{return this.controlResponse(res)})
    }

    getFootage(rid) {
        return this.interface.post('analytics/get-footage', {rid: rid}).then(res=>{return this.controlResponse(res)})
    }

    updateRoi(roi) {
        return this.interface.post('analytics/update-roi', {roi: roi}).then(res=>{return this.controlResponse(res)})
    }
    deleteRoi(roi) {
        return this.interface.post('analytics/delete-roi', {roi: roi}).then(res=>{return this.controlResponse(res)})
    }
    createNewRoi(rid, name) {
        return this.interface.post('analytics/create-new-roi', {rid: rid,roi_name: name}).then(res=>{return this.controlResponse(res)})
    }
    getKeyStream(key) {
        return this.interface.post('stream/get-key-stream', {key: key}).then(res=>{return this.controlResponse(res)});
    }
    getUnreadMailCount() {
        return this.interface.post('mails/get-unread-mails').then(res=>{return this.controlResponse(res)})
    }
    getMails(annotation = false) {
        return this.interface.post('mails/get-mails', {annotation: annotation}).then(res=>{return this.controlResponse(res)})
    }
    uploadMailImage(img) {
        let formData = new FormData();
        formData.append('image', img);
        return this.interface.post('uploads/image', formData).then(res=>{return this.controlResponse(res)})
    }
    saveMail(blocks, title, approved, published) {
        return this.interface.post('mails/save-mail',
            {blocks: blocks, title: title, approved: approved, published: published})
            .then(res=>{return this.controlResponse(res)})
    }
    editMail(id, blocks, title, approved, published) {
        return this.interface.post('mails/edit-mail',
            {id: id, blocks: blocks, title: title, approved: approved, published: published})
            .then(res=>{return this.controlResponse(res)})
    }
    getMail(mailId) {
        return this.interface.post('mails/get-mail', {id: mailId}).then(res=>{return this.controlResponse(res)})
    }

    updateDolakData(season, days, hours) {
        return this.interface.post('analytics/dolak-data', {season: season, days: days, hours: hours}).then(res=> {
            return this.controlResponse(res)
        });
    }
    getDolakTableDetail(season, days, hours, tableId) {
        return this.interface.post('analytics/dolak-table-detail',
            {season: season, days: days, hours: hours, tableId: tableId}).then(res=> {
            return this.controlResponse(res)
        });
    }
    getDolakRecordSrc(id) {
        return this.interface.post('analytics/dolak-record-src', {id: id}).then(res=>this.controlResponse(res));
    }

}
