import {Component} from "react";
import Popup from "reactjs-popup";
import {toast} from "react-toastify";
import LocalizedStrings from "react-localization";
import TorlinTable from "../../libraries/TorlinTable/TorlinTable";
import Loader from "../../libraries/Loader/Loader";
import RoleForm from "./RoleForm";

let strings = new LocalizedStrings({
    en: {
        role: 'Role',
        parent: 'Parent',
        options: 'Options',
        roleSaved: 'Role was saved',
        newRole: 'New Role',
        deleteRoleTitle: 'Delete of Role',
        deleteUserContent: 'Do you really want to delete this role?',
        roleDeleted: 'Role was deleted',
        delete: 'Delete',
        cancel: 'Cancel'
    },
    cs: {
        role: 'Role',
        parent: 'Rodič',
        options: 'Možnosti',
        roleSaved: 'Role byla uložena',
        newRole: 'Nová Role',
        deleteRoleTitle: 'Smazání role',
        deleteUserContent: 'Opravdu chcete tuto roli smazat?',
        roleDeleted: 'Role byla smazána',
        delete: 'Smazat',
        cancel: 'Zrušit'
    }

})

export default class RolesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            roles: false,
            view: 'list',
            editRow: false,
            columns: [
                {
                    Header: strings.role,
                    accessor: 'name',
                },
                {
                    Header: strings.parent,
                    accessor: 'parent',
                    Cell: (elm)=>{
                        let value = elm.row.original.parents;
                        if (value.length) {
                            return value.map(row=>{
                                return row.name + ', ';
                            })
                        } else {
                            return ''
                        }
                    }
                },
                {
                    Header: strings.options,
                    id: 'options',
                    sortable: false,
                    accessor: 'id',
                    Cell: (table) => {
                        let data = table.row.original;
                        let value = table.row.original.id;
                        return <>
                            <button className={'btn-edit'} onClick={()=>{this.editRow(data);}}>Edit</button>
                            <Popup api={this.props.api} trigger={<button className={'btn-delete'}>Remove</button>} modal>
                                {close => (
                                    <div className="modal">
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="header"> {strings.deleteRoleTitle} </div>
                                        <div className="content">
                                            {strings.deleteUserContent}
                                        </div>
                                        <div className="actions">
                                            <button
                                                className={'btn-danger'}
                                                onClick={()=>{
                                                    this.props.api.deleteRole(value).then(res=>{
                                                        if (res.status === 'error') {
                                                            toast.error('error'+res.message);
                                                        } else {
                                                            toast.success(strings.roleDeleted);
                                                            this.componentDidMount();
                                                            this.setState({view: 'list', editRow: null, loaded: false});
                                                            close();
                                                        }
                                                    })
                                                }}
                                            >
                                                {strings.delete}
                                            </button>
                                            <button
                                                className={'btn-cancel'}
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                {strings.cancel}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </>
                    },
                    disableSortBy: true,
                    Filter: false,
                },
            ]
        }
    }

    editRow(value) {
        this.setState({editRow: value, view: 'edit'})
    }

    componentDidMount() {
        this.props.api.getRolesList().then(res=>{
            this.setState({
                roles: res,
                loaded: true
            })
        })
    }

    render() {
        if (this.state.loaded) {
            if (this.state.view === 'list') {
                return <>
                    <button className={'btn btn-new'} onClick={()=>{this.setState({view: 'newRow'})}}>{strings.newRole}</button>
                    <TorlinTable columns={this.state.columns} data={this.state.roles}/>
                </>
            } else if (this.state.view === 'edit') {
                return <><RoleForm {...this.props}
                                   cancelCallback={()=>{this.setState({view: 'list', editRow: null})}}
                                   updateCallback={()=>{
                                       toast.success(strings.roleSaved);
                                       this.setState({view: 'list', editRow: null, loaded: false},()=>{this.componentDidMount()})}}
                                   role={this.state.editRow}
                                   roles={this.state.roles} /></>
            } else if (this.state.view === 'newRow') {
                return <><RoleForm {...this.props}
                                   cancelCallback={()=>{this.setState({view: 'list', editRow: null})}}
                                   updateCallback={()=>{
                                       toast.success(strings.roleSaved);
                                       this.setState({view: 'list', editRow: null, loaded: false},()=>{this.componentDidMount()})}}
                                   roles={this.state.roles} new /></>
            }
        } else {
            return <Loader />
        }
    }
}
